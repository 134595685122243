import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import i18n from "../i18n/i18n";
import instance, { useAxios, useAxiosSeconde } from "../api/api";
import { useParams, useNavigate } from "react-router-dom";

const people = [
  { id: 1, name: "Amira Alexander" },
  { id: 2, name: "Mazin Alexander" },
  { id: 3, name: "Ahmad Alexander" },
  // More users...
];

function Search({ isSearch, setIsSearch, t, company }) {
  const navigate = useNavigate();

  const [products, setProducts] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [newOject, setNewObject] = useState({
    search: "",
    // password: "",
  });

  const filteredPeople =
    newOject.search === ""
      ? products
      : products.filter((p) => {
          return p.name.toLowerCase().includes(newOject.search.toLowerCase());
        });

  const textCutFunc = (text) =>
    `${text
      .trim()
      .split(" ")
      .splice(0, text?.length > 25 ? 7 : 5)
      .join(" ")}  ${text?.length > 25 ? "..." : ""}`;

  //   const [isLoading, setIsLoading] = useState(false);

  const handleFields = async (e) => {
    // setHandleError("");
    setNewObject({ ...newOject, [e.target.name]: e.target.value });
    // console.log("d: ", e.target.value, company);
    if (e.target.value.length > 3) {
      await instance
        .post(`/products/search`, {
          headers: {
            Authorization:
              "ahmadtech199-test a0762f190f3142405efc8e7b4d1de634ddaa8d7f",
            "Content-Type": "application/json",
          },

          keyword: "naem",
        })
        .then(({ data }) => setProducts(data));
    } else {
      console.log("here: ", e.target.value.length);
      setProducts([]);
    }
  };

  return (
    // <Transition.Root show={isSearch} as={Fragment}>
    //   <Dialog
    //     as="div"
    //     className="w-full absolute bg-yellow-400"
    //     onClose={setIsSearch}
    //   >
    //     <Transition.Child
    //       as={Fragment}
    //       enter="ease-out duration-300"
    //       enterFrom="opacity-0"
    //       enterTo="opacity-100"
    //       leave="ease-in duration-200"
    //       leaveFrom="opacity-100"
    //       leaveTo="opacity-0"
    //       className="w-full bg-red-500"
    //     >
    //       {/* <div className="bg-red-300">jj</div> */}
    //       <div className="">
    //         <div class="w-full max-w-screen-xl mx-auto px-6 ">
    //           <div class="flex justify-center p-4 px-3 py-10">
    //             <div class="w-full max-w-md">
    //               <div class="bg-white shadow-md rounded-lg px-3 py-2 mb-4">
    //                 <div class="block text-gray-700 text-lg font-semibold py-2 px-2">
    //                   Item List
    //                 </div>
    //                 <div class="flex items-center bg-gray-200 rounded-md">
    //                   <div class="pl-2">
    //                     <svg
    //                       class="fill-current text-gray-500 w-6 h-6"
    //                       xmlns="http://www.w3.org/2000/svg"
    //                       viewBox="0 0 24 24"
    //                     >
    //                       <path
    //                         class="heroicon-ui"
    //                         d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
    //                       />
    //                     </svg>
    //                   </div>
    //                   <input
    //                     class="w-full rounded-md bg-gray-200 text-gray-700 leading-tight focus:outline-none py-2 px-2"
    //                     id="search"
    //                     type="text"
    //                     placeholder="Search teams or members"
    //                   ></input>
    //                 </div>
    //                 <div class="py-3 text-sm">
    //                   <div class="flex justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2">
    //                     <span class="bg-gray-400 h-2 w-2 m-2 rounded-full"></span>
    //                     <div class="flex-grow font-medium px-2">
    //                       Tighten Co.
    //                     </div>
    //                     <div class="text-sm font-normal text-gray-500 tracking-wide">
    //                       Team
    //                     </div>
    //                   </div>
    //                   <div class="flex justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2">
    //                     <span class="bg-green-400 h-2 w-2 m-2 rounded-full"></span>
    //                     <div class="flex-grow font-medium px-2">
    //                       Taylor Otwell
    //                     </div>
    //                     <div class="text-sm font-normal text-gray-500 tracking-wide">
    //                       Member
    //                     </div>
    //                   </div>
    //                   <div class="flex justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2">
    //                     <span class="bg-gray-400 h-2 w-2 m-2 rounded-full"></span>
    //                     <div class="flex-grow font-medium px-2">
    //                       Adam Wathan
    //                     </div>
    //                     <div class="text-sm font-normal text-gray-500 tracking-wide">
    //                       Member
    //                     </div>
    //                   </div>
    //                   <div class="flex justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2">
    //                     <span class="bg-gray-400 h-2 w-2 m-2 rounded-full"></span>
    //                     <div class="flex-grow font-medium px-2">
    //                       Duke Street Studio Inc.
    //                     </div>
    //                     <div class="text-sm font-normal text-gray-500 tracking-wide">
    //                       Team
    //                     </div>
    //                   </div>
    //                   <div class="flex justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2">
    //                     <span class="bg-green-400 h-2 w-2 m-2 rounded-full"></span>
    //                     <div class="flex-grow font-medium px-2">
    //                       Jeffrey Wey
    //                     </div>
    //                     <div class="text-sm font-normal text-gray-500 tracking-wide">
    //                       Member
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div class="block bg-gray-200 text-sm text-right py-2 px-3 -mx-3 -mb-2 rounded-b-lg">
    //                   <button
    //                     onClick={() => setIsSearch(false)}
    //                     class="hover:text-gray-600 text-gray-500 font-bold py-2 px-4"
    //                   >
    //                     Cancel
    //                   </button>
    //                   <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
    //                     Invite
    //                   </button>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </Transition.Child>
    //   </Dialog>
    // </Transition.Root>

    <Transition.Root show={isSearch} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        //   initialFocus={cancelButtonRef}
        onClose={setIsSearch}
      >
        <Transition.Child
          as={Fragment}
          //   enter="transform ease-out duration-300"
          //   enterFrom="opacity-0"
          //   enterTo="opacity-100"
          //   leave="ease-in duration-200"
          //   leaveFrom="opacity-100"
          //   leaveTo="opacity-0"
          enter="transform ease-out duration-500 transition"
          enterFrom={
            i18n.dir() === "rtl"
              ? "translate-y-0 opacity-100 sm:translate-x-0"
              : "translate-y-0 opacity-100 sm:translate-x-0"
          }
          enterTo={
            i18n.dir() === "rtl"
              ? "opacity-100 translate-y-2 translate-x-0"
              : "opacity-100 translate-y-2 translate-x-0"
          }
          leave="transition ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-y-20 inset-x-6 sm:inset-20">
            <div className="">
              <div class="w-full max-w-screen-xl sm:mx-auto">
                <div class="flex justify-center p-4 px-3 py-10">
                  <div class="w-full max-w-md">
                    <div class="bg-white shadow-md rounded-lg px-3 py-2 mb-4">
                      <div class="block text-gray-700 text-lg font-semibold py-2 px-2">
                        {t("lookfor")}
                      </div>
                      <div class="flex items-center bg-gray-200 rounded-md">
                        <div class="pl-2">
                          <svg
                            class="fill-current text-gray-500 w-6 h-6"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path
                              class="heroicon-ui"
                              d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"
                            />
                          </svg>
                        </div>
                        <input
                          class="w-full rounded-md bg-gray-200 text-gray-700 leading-tight focus:outline-none py-2 px-2"
                          id="search"
                          type="text"
                          placeholder={t("typeInProductName")}
                          name="search"
                          value={newOject.search}
                          onChange={(e) => handleFields(e)}
                          autoComplete="search"
                        ></input>
                      </div>
                      <div class="py-0 text-sm">
                        {filteredPeople.length > 0 ? (
                          filteredPeople.map((person, key) => (
                            <div
                              key={key}
                              class="flex justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2"
                              onClick={() => {
                                setIsSearch(false);
                                navigate(`/detail/${person._id}`);
                              }}
                            >
                              {/* <span class="bg-green-600 h-2 w-2 m-2 rounded-full"></span> */}
                              <img
                                className="h-5 w-5 rounded"
                                src={person.image.split(",")[0]}
                              />
                              <div class="flex-grow font-medium px-2">
                                {textCutFunc(person.name)}
                              </div>
                              <div class="text-sm font-normal text-green-600 tracking-wide">
                                {t("available")}
                              </div>
                            </div>
                          ))
                        ) : products.length === 0 &&
                          newOject.search.length > 3 ? (
                          <div class="flex justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2">
                            <span class="bg-red-400 h-2 w-2 m-2 rounded-full"></span>
                            <div class="flex-grow font-medium px-2">
                              {t("notFoundBySearch")}
                            </div>
                            <div class="text-sm font-normal text-gray-500 tracking-wide"></div>
                          </div>
                        ) : newOject.search.length <= 3 &&
                          newOject.search.length > 0 ? (
                          <div class="flex justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2">
                            <span class="bg-red-400 h-2 w-2 m-2 rounded-full"></span>
                            <div class="flex-grow font-medium px-2">
                              {t("searching")}
                            </div>
                            <div class="text-sm font-normal text-gray-500 tracking-wide"></div>
                          </div>
                        ) : (
                          <div class="flex justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2">
                            <span class="bg-red-400 h-2 w-2 m-2 rounded-full"></span>
                            <div class="flex-grow font-medium px-2">
                              {t("nothingWrittenInSearch")}
                            </div>
                            <div class="text-sm font-normal text-gray-500 tracking-wide"></div>
                          </div>
                        )}
                      </div>

                      {/* <div>
                        <div class="flex justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2">
                          <span class="bg-gray-400 h-2 w-2 m-2 rounded-full"></span>
                          <div class="flex-grow font-medium px-2">
                            Tighten Co.
                          </div>
                          <div class="text-sm font-normal text-gray-500 tracking-wide">
                            Team
                          </div>
                        </div>
                        <div class="flex justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2">
                          <span class="bg-green-400 h-2 w-2 m-2 rounded-full"></span>
                          <div class="flex-grow font-medium px-2">
                            Taylor Otwell
                          </div>
                          <div class="text-sm font-normal text-gray-500 tracking-wide">
                            Member
                          </div>
                        </div>
                        <div class="flex justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2">
                          <span class="bg-gray-400 h-2 w-2 m-2 rounded-full"></span>
                          <div class="flex-grow font-medium px-2">
                            Adam Wathan
                          </div>
                          <div class="text-sm font-normal text-gray-500 tracking-wide">
                            Member
                          </div>
                        </div>
                        <div class="flex justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2">
                          <span class="bg-gray-400 h-2 w-2 m-2 rounded-full"></span>
                          <div class="flex-grow font-medium px-2">
                            Duke Street Studio Inc.
                          </div>
                          <div class="text-sm font-normal text-gray-500 tracking-wide">
                            Team
                          </div>
                        </div>
                        <div class="flex justify-start cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2">
                          <span class="bg-green-400 h-2 w-2 m-2 rounded-full"></span>
                          <div class="flex-grow font-medium px-2">
                            Jeffrey Wey
                          </div>
                          <div class="text-sm font-normal text-gray-500 tracking-wide">
                            Member
                          </div>
                        </div>
                      </div> */}
                      <div class="block bg-gray-200 text-sm ltr:text-right rtl:text-left py-2 px-3 -mx-3 -mb-2 rounded-b-lg">
                        <button
                          onClick={() =>
                            setNewObject({ ...newOject, search: "" }) &
                            setIsSearch(false)
                          }
                          class="hover:text-gray-600 text-gray-500 font-bold py-2 px-4"
                        >
                          {t("cancel")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}

export default Search;
