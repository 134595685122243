import { PaperClipIcon } from "@heroicons/react/20/solid";
import jwt_decode from "jwt-decode";
import { useEffect, useState, useRef } from "react";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";

export default function Profile({ t }) {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  const oneFetch = useRef(true);
  useEffect(() => {
    if (oneFetch.current) {
      // console.log(
      //   "onFetch: ",
      //   oneFetch.current,
      //   localStorage.getItem("Token") != null
      // );
      oneFetch.current = false;
      var decoded = jwt_decode(localStorage.getItem("Token"));
      setUser(decoded.user);

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
    // else{
    //   navigate('/login')
    //   // oneFetch.current = true;
    // }
  }, []);

  return (
    <div className=" bg-white sm:container sm:mx-auto mt-5 mx-5">
      {isLoading ? (
        <div className="flex items-center justify-center w-full py-20">
          <div className="flex justify-center items-center space-x-1 text-sm text-gray-700">
            <svg
              fill="none"
              className="w-20 h-20 animate-spin"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
      ) : (
        <>
          <div className="overflow-hidden shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                {t("userInformation")}
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {t("userDetails")}
              </p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    {t("username")} 
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {user?.displayName}
                  </dd>
                </div>

                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    {t("email")}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {user?.email}
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    {t("password")}
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    ********
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <button
            type="button"
            className="my-5 inline-flex items-center gap-x-2 rounded-md bg-red-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => {
              localStorage.removeItem("Token");
              localStorage.removeItem("Order");
              localStorage.setItem("CountCart", 0);
              navigate("/login");
            }}
          >
            <ArrowRightOnRectangleIcon
              className="-ml-0.5 h-5 w-5 ltr:rotate-180  "
              aria-hidden="true"
            />
            {t("logout")}
          </button>
        </>
      )}
    </div>
  );
}
