import { PaperClipIcon } from "@heroicons/react/20/solid";
import jwt_decode from "jwt-decode";
import { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAxios, useAxiosDublecate } from "../api/api";
import { Context } from "../context/ContextProvider";
import Pagination from "../components/Pagination";
import {
  ArrowUturnRightIcon,
  CheckIcon,
  ClockIcon,
  QuestionMarkCircleIcon,
  XMarkIcon as XMarkIconMini,
} from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Orders({ t }) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const {
    categories,
    items,
    setItems,
    addOrder,
    setOrders,
    orders,
    newList,
    setNewList,
    amountOfItemsInCart,
    handleAddProduct,
    cartItmes,
    setCartItems,
    handleRemoveProduct,
    totalPrice,
    setCategories,
    company,
  } = useContext(Context);
  const navigate = useNavigate();

  // const { response, loading, error } = useAxios({
  //   method: "GET",
  //   url: `/companies/get-company-name/${
  //     localStorage.getItem("CN") !== null && localStorage.getItem("CN")
  //   }`,
  //   headers: {
  //     accept: "*/*",
  //   },
  // });

  const { data, loading, error } = useAxiosDublecate(
    jwt_decode(localStorage.getItem("Token")).user._id,
    {
      method: "GET",
      url: `/companies/get-company-name/${
        localStorage.getItem("CN") !== null && localStorage.getItem("CN")
      }`,
      headers: {
        accept: "*/*",
      },
    },
    {
      method: "GET",
      url: "/invoices/get-casheir-invoices-for-user/",
      headers: {
        accept: "*/*",
      },
    }
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(
    window.innerHeight < 700
      ? 5
      : window.innerHeight < 900
      ? 8
      : window.innerHeight < 1000
      ? 10
      : 15
  );

  // Get current posts
  const indexOfLastPost = currentPage * ordersPerPage;
  const indexOfFirstPost = indexOfLastPost - ordersPerPage;
  const currentOrders = data?.slice(indexOfFirstPost, indexOfLastPost);

  //   setTimeout(() => {
  //     console.log("response: ");
  //   }, 3000);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const oneFetch = useRef(true);
  useEffect(() => {
    if (oneFetch.current) {
      oneFetch.current = false;
      setTimeout(() => {
        setIsLoading(false);
        // console.log("jj: ",orders);
      }, 1000);
    }
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center w-full py-20">
          <div className="flex justify-center items-center space-x-1 rtl:space-x-reverse text-sm text-gray-700">
            <svg
              fill="none"
              className="w-20 h-20 animate-spin"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
      ) : data?.length === 0 ? (
        <div className="container mx-auto mt-2">
          <div className="flex flex-row justify-between px-5 bg-gray-200 p-3 rounded ">
            <h1 className="text-1xl font-bold tracking-tight text-gray-900 sm:text-xl mt-0.5">
              {t("notFindOrder")}
            </h1>
            <a
              href={`/${localStorage.getItem("CN")}`}
              className="flex flex-row bg-gray-600 rounded px-2 text-white "
            >
              <span className="text-1xl font-bold tracking-tight  sm:text-1xl pl-3 pr-3 pb-1 sm:mt-0.5">
                {t("back")}
              </span>
              <ArrowUturnRightIcon
                className="h-5 w-5 mt-0.5 sm:mt-1.5"
                aria-hidden="true"
              />
            </a>
          </div>
        </div>
      ) : (
        <div className="overflow-hidden shadow sm:rounded-lg m-5">
          <div className="px-4 sm:px-6 lg:px-8 mt-5">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-base font-semibold leading-6 text-gray-900">
                  {t("orders")}
                </h1>
                {/* <p className="mt-2 text-sm text-gray-700">
              A list of all the users in your account including their name, title, email and role.
            </p> */}
              </div>
              {/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Add user
            </button>
          </div> */}
            </div>
            <div className="mt-2 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 mx-4 sm:mx-0">
                  <table className="min-w-full divide-y divide-gray-300 ">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 ltr:pl-0 rtl:pr-0 ltr:pr-3 rtl:pl-3 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900 sm:pl-0"
                        >
                          {t("itemNo")}
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900"
                        >
                          {t("date")}
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900"
                        >
                          {t("orderStatus")}
                        </th>
                        {/* <th
                      scope="col"
                      className="px-3 py-3.5 ltr:text-left rtl:text-right text-sm font-semibold text-gray-900"
                    >
                      Role
                    </th> */}
                        <th
                          scope="col"
                          className="relative py-3.5 ltr:pl-3 rtl:pr-3  ltr:pr-4 rtl:pl-4 sm:pr-0"
                        >
                          <span className="sr-only">{t("details")}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {currentOrders.map((order, key) => (
                        <tr key={key}>
                          <td className="whitespace-nowrap py-4 ltr:pl-0 rtl:pr-0 pr-3 text-sm sm:pl-0">
                            <div className="flex items-center">
                              {/* <div className="h-10 w-10 flex-shrink-0">
                            <img className="h-10 w-10 rounded-full" src={order.image} alt="" />
                          </div> */}
                              <div className="ltr:ml-0 rtl:mr-0">
                                <div className="font-medium text-gray-900">
                                  {order.itemNo}
                                </div>
                                {/* <div className="text-gray-500">{order.email}</div> */}
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-gray-900">
                              {new Date(order.created_at).getDate()}-
                              {new Date(order.created_at).getMonth()}-
                              {new Date(order.created_at).getFullYear()}
                            </div>
                            <div className="text-gray-500">
                              {/* {order.create_At} */}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span
                              className={classNames(
                                order.isCancel
                                  ? "text-red-800 bg-red-100 "
                                  : order.isShipped &&
                                    order.isRefund === false &&
                                    order.isEndRefund === false
                                  ? "text-green-800 bg-green-100 "
                                  : order.isShipped &&
                                    order.isRefund &&
                                    order.isEndRefund === false
                                  ? "bg-orange-100 text-orange-500"
                                  : order.isEndRefund === true
                                  ? "bg-gray-100 text-gray-500"
                                  : "bg-yellow-100 text-yellow-500",
                                "inline-flex rounded-full  px-2 text-xs font-semibold  leading-5 "
                              )}
                            >
                              {order.isCancel
                                ? `${t("cancelOrder")}`
                                : order.isShipped &&
                                  order.isRefund === false &&
                                  order.isEndRefund === false
                                ? `${t("shipped")}`
                                : order.isShipped &&
                                  order.isRefund &&
                                  order.isEndRefund === false
                                ? `${t("pending1")}`
                                : order.isEndRefund === true
                                ? `${t("refunded")}`
                                : `${t("pending")}`}
                            </span>
                          </td>
                          <td className="relative whitespace-nowrap py-4 ltr:pl-3 rtl:pr-3 ltr:pr-4 rtl:pl-4 ltr:text-left rtl:text-right text-sm font-medium sm:pr-0">
                            <button
                              onClick={() =>
                                navigate(`/order/${order.tran_ref}`, {
                                  state: {
                                    invoice: order,
                                    //...values
                                  },
                                })
                              }
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              {t("details")}
                              <span className="sr-only">, {order.name}</span>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            ordersPerPage={ordersPerPage}
            totalOrders={data?.length}
            paginate={paginate}
            t={t}
          />
        </div>
      )}
    </>
  );
}
