import ProductView from "./ProductView";
import { useNavigate, useParams } from "react-router-dom";
import instance, { useAxios, useAxiosSeconde } from "../api/api";
import { Fragment, useState, useEffect, useContext, useRef } from "react";
import { Context } from "../context/ContextProvider";

import {
  Dialog,
  Popover,
  Tab,
  Transition,
  Disclosure,
} from "@headlessui/react";
import {
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  GlobeAltIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import NavDisClosure from "../components/NavDisClosure";
import Notifications from "../components/Notifications";
import AlerteMoreSizeAndColor from "../components/AlerteMoreSizeAndColor";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProductList({
  lang,
  open,
  setOpen,
  changeLanguage,
  t,
}) {
  const {
    categories,
    items,
    setItems,
    addOrder,
    setOrders,
    orders,
    newList,
    setNewList,
    amountOfItemsInCart,
    handleAddProduct,
    cartItmes,
    setCartItems,
    handleRemoveProduct,
    totalPrice,
    setCategories,
    setCompany,
    show,
    setShow,
    isSearch,
    setIsSearch,
  } = useContext(Context);

  const oneFetch = useRef(true);

  const navigate = useNavigate();
  const { companyName } = useParams();

  // const [categories, setCategories] = useState([]);
  const [select, setSelect] = useState("all");
  // const [items, setItems] = useState([]);
  const [value, setValue] = useState(0);
  const [limit, setLimit] = useState(10);

  // const { response, loading, error } = useAxios({
  //   method: "GET",
  //   url: `/companies/company-limits?companyName=${companyName}&limit=${limit}`, // products/company-limit?company=62160bc81c5ceec125780794&limit=5
  //   headers: {
  //     // no need to stringify
  //     accept: "*/*",
  //   },
  //   // data: {  // no need to stringify
  //   //     userId: 1,
  //   //     id: 19392,
  //   //     title: 'title',
  //   //     body: 'Sample text',
  //   // },
  // });
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingBottom, setLoadingBottom] = useState(false);

  useEffect(() => {
    // if (oneFetch.current || loading) {
    const fetchData = async () => {
      setLoadingBottom(true);
      // oneFetch.current = false;
      // setLoading(false);
      try {
        const response = await instance.get(
          `/companies/company-limits?companyName=${localStorage.getItem(
            "CN"
          )}&limit=${limit}`
        );
        // console.log("da: ", response.data);
        setResponse(response.data);
        setItems(response.data);

        setLoading(false);
        setLoadingBottom(false);
      } catch (error) {
        console.log("error:  ", error.message);
      }
    };
    fetchData();
    // }
  }, [limit]);

  const { data, loadingSeconde, errorSeconde } = useAxiosSeconde({
    method: "GET",
    url: `/companies/get-company-name/${localStorage.getItem("CN")}`,
    headers: {
      accept: "*/*",
    },
  });

  // if (loadingSeconde) {

  //   console.log("here ...");
  // }

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (oneFetch.current || loading === false) {
      localStorage.getItem("Order") != null &&
        setCartItems(JSON.parse(localStorage.getItem("Order")));
      oneFetch.current = false;
      setCategories([
        "all",
        ...new Set(
          response
            ?.map((item) => item.category)
            .filter((category) => category.trim() !== "")
        ),
      ]);

      // console.log("d: ", companyName);

      localStorage.setItem("CN", companyName);

      // console.log("c: ",  localStorage.getItem("Order") == null);
      // console.log("company: ",  jj);
      filterItems("all");
      setTimeout(() => {
        // console.log("here ...,",data);
        setCompany(data);
        setIsLoading(false);
      }, 100);
    }
  }, [loading]);

  const handleNotification = () => {
    setShow(true);

    setTimeout(() => {
      setShow(false);
    }, 500);
  };

  const textCutFunc = (text) =>
    `${text
      .trim()
      .split(" ")
      .splice(0, text?.length > 25 ? 3 : 4)
      .join(" ")}  ${text?.length > 25 ? "..." : ""}`;

  const filterItems = (category) => {
    if (category === "all") {
      setItems(response);
      setSelect("all");
      return;
    }

    const newItems = response.filter((item) => item.category === category);

    if (newItems.length > 0) {
      setSelect(newItems[0].category);
      setItems(newItems);
    } else if (items.length < 30) {
    } else {
    }
  };

  const handleChange = (newValue) => {
    console.log("new: ", newValue.target.value);
    // setValue(newValue.target.value);
    // console.log("new Value: ", categories[newValue]);

    filterItems(categories[newValue.target.value]);
  };
  const ref = useRef(null);

  //   useEffect(() => {

  //   onScroll()

  // }, [ref])

  // const onScroll = () => {
  //   let current = ref.current;
  //   if (current) {
  //     const { scrollTop, clientHeight, scrollHeight } = current;
  //     const computedScrollHeight = Math.round(scrollTop) + clientHeight;
  //     if (
  //       scrollHeight >= computedScrollHeight - 1 &&
  //       scrollHeight <= computedScrollHeight + 1
  //     ) {
  //       console.log("Reached Bottom");
  //     }
  //   }
  //   // console.log("here .", current);
  // };

  const [smallHeight, setSmallHeight] = useState(true);

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 100 || smallHeight == false) {
      // console.log("limit: ", limit);
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  const onScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    toggleVisibility();
    if (event) {
      const computedScrollHeight = Math.round(scrollTop) + clientHeight;

      if (
        scrollHeight >= computedScrollHeight - 1 &&
        scrollHeight <= computedScrollHeight + 1
      ) {
        console.log(
          "Reached Bottom",
          scrollTop,
          clientHeight,
          computedScrollHeight,
          // index
          limit
        );

        // setIsLoading(true)
        // setLoading(true)
        setLimit(limit + 10);
      }
      if (scrollTop === 0) setSmallHeight(true);
    }
  };

  const [openAlerteMore, setOpenAlerteMore] = useState(false);
  const [isSendAlerteMore, setIsSendAlerteMore] = useState(false);
  const [selectProduct, setSelectProduct] = useState({});

  return (
    <div
      onScroll={onScroll}
      className={classNames(
        smallHeight ? "h-screen" : "",
        "bg-white overflow-y-auto overflow-x-hidden scroll-smooth"
      )}
    >
      <AlerteMoreSizeAndColor
        openAlerteMore={openAlerteMore}
        setOpenAlerteMore={setOpenAlerteMore}
        isSendAlerteMore={isSendAlerteMore}
        setIsSendAlerteMore={setIsSendAlerteMore}
        t={t}
        selectProduct={selectProduct}
        setShow={setShow}
        handleAddProduct={handleAddProduct}
        cartItmes={cartItmes}
        handleNotification={handleNotification}
      />
      <Notifications title={t("addedToCart")} />
      {loading ? (
        <div className="flex justify-center items-center py-20">
          <div className="flex justify-center items-center space-x-1 space-x-reverse text-sm text-gray-700">
            <svg
              fill="none"
              className="w-20 h-20 animate-spin"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
      ) : (
        <div className={classNames(isSearch ? "blur   " : "")}>
          <header className="relative bg-white mx-auto max-w-2xl md:px-4 md:max-w-7xl lg:px-8">
            <nav aria-label="Top" className="flex justify-start ">
              <div className="flex h-16 ">
                {/* Flyout menus */}

                <div className="sm:hidden absolute inset-x-0 bottom-0 sm:static sm:flex-1 sm:self-stretch rtl:ml-10 ltr:mr-10 ">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                  {/** focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 */}
                  <select
                    id="tabs"
                    name="tabs"
                    className="block border w-full pl-5 mx-5 px-3 py-2 text-base border-gray-300  sm:text-sm rounded-md hover:bg-gray-300"
                    // defaultValue={categories?.find((tab) => tab == select)}
                    // aria-current={select ? select : undefined}
                    // value={select}
                    onChange={(e) => handleChange(e)}
                  >
                    {categories?.map((tab, index) => (
                      <option key={index} value={index}>
                        {tab === "all" ? `${t("all")}` : tab}
                      </option>
                    ))}
                  </select>
                </div>
                <Popover.Group className="absolute inset-x-0 bottom-0  flex-1 self-stretch hidden sm:block">
                  <div className="flex h-16 space-x-3 rtl:space-x-reverse overflow-x-auto px-3 xl:px-4 pb-px ">
                    {categories?.map((category, categoryIdx) => (
                      <Popover key={categoryIdx} className="flex">
                        {({ open }) => (
                          <>
                            <div className="relative flex">
                              <Popover.Button
                                // onClick={}
                                value={categoryIdx}
                                onClick={(e) => handleChange(e)}
                                className={classNames(
                                  open || categories[categoryIdx] === select
                                    ? "border-black text-black "
                                    : " text-gray-500 hover:text-gray-800 text-bold border-gray-300",
                                  "hover:bg-gray-200 hover:rounded  mt-3 relative z-8 -mb-px flex items-center border-b-4 pt-px text-sm font-medium transition-colors duration-200 ease-out  md:px-4 focus:outline-0"
                                )}
                              >
                                {category === "all" ? `${t("all")}` : category}
                              </Popover.Button>
                            </div>
                          </>
                        )}
                      </Popover>
                    ))}
                  </div>
                </Popover.Group>
              </div>
            </nav>
          </header>
          <div className="mx-auto max-w-2xl py-6 px-6 sm:py-6 lg:max-w-7xl lg:px-8">
            <div className="md:flex md:items-center md:justify-between">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900">
                {/* لا تفوتك */}
                {t("best")}
              </h2>
            </div>

            <div className="mt-6 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-3 xl:grid-cols-4 md:gap-y-6 lg:gap-x-8 ">
              {items?.map((product) => (
                <div key={product._id} className="group relative">
                  <button
                    onClick={() => navigate(`/detail/${product._id}`)}
                    className="text-start"
                  >
                    <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80">
                      <img
                        src={
                          product.image.includes(",")
                            ? product.image.split(",")[0]
                            : product.image
                        }
                        alt={product.imageAlt}
                        // className="h-full w-full object-cover object-center"
                        className={classNames(
                          isLoading ? "animate-pulse w-full" : "",
                          "h-full w-full object-cover object-center"
                        )}
                        style={{
                          animationFillMode: "backwards",
                          animationDelay: "150ms",
                        }}
                      />
                    </div>
                    <h3
                      className={classNames(
                        isLoading ? "animate-pulse w-full" : "",
                        "mt-4 text-sm text-gray-700"
                      )}
                    >
                      <a href={product.href}>
                        <span className="absolute inset-0" />
                        {product.name ? textCutFunc(product.name) : ""}
                      </a>
                    </h3>
                    <p className="mt-1 text-sm text-gray-400">
                      {product.marker}
                    </p>
                    <div className="flex flex-row">
                      <p className="mt-1 text-xs text-green-600 ltr:mr-2 rtl:ml-2">
                        {product.unit
                          ? product.unit?.toFixed(2) + " " + t("sar")
                          : ""}
                      </p>
                      <p className="mt-1 text-xs text-red-600 line-through">
                        {product.basicPrice
                          ? product.basicPrice?.toFixed(2) + " " + t("sar")
                          : ""}
                      </p>
                    </div>
                  </button>

                  <div className="mt-6">
                    <button
                      onClick={() => {
                        if (
                          product.sizes?.length > 0 ||
                          product.color?.length > 0
                        ) {
                          console.log("product: ", product);
                          // alert("توجد اختيارات مختلفة من هذا المنتج")
                          setSelectProduct(product);
                          setOpenAlerteMore(true);
                        } else {
                          setShow(false);
                          handleAddProduct(product) & (cartItmes?.length > 0) &&
                            localStorage.setItem(
                              "Order",
                              JSON.stringify(cartItmes)
                            );
                          handleNotification();
                        }
                      }}
                      className="w-full font-bold relative flex items-center justify-center rounded-md border border-transparent bg-gray-100 px-8 py-2 text-sm   hover:bg-gray-200 focus:bg-indigo-600 focus:text-white text-gray-900"
                    >
                      {t("toCart")} <span className="sr-only"></span>
                    </button>
                  </div>
                </div>
              ))}

              {loadingBottom && (
                <div className="flex justify-center items-center py-20">
                  <div className="flex justify-center items-center space-x-1 space-x-reverse text-sm text-gray-700">
                    <svg
                      fill="none"
                      className="w-20 h-20 animate-spin"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clipRule="evenodd"
                        d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                        fill="currentColor"
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="flex w-full h-15  pl-10 pb-10 justify-end">
        <button
          type="button"
          data-mdb-ripple="true"
          data-mdb-ripple-color="light"
          className={classNames(
            isVisible ? " opacity-100" : "opacity-0",
            // " inline-block p-3 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out bottom-5 right-5"
            "fixed z-90 bottom-20 ltr:right-8 rtl:left-8 bg-blue-600  w-16  h-16 rounded-full drop-shadow-lg flex justify-center items-center text-white text-4xl hover:bg-blue-700 hover:drop-shadow-2xl"
          )}
          id="btn-back-to-top"
          onClick={() => {
            setSmallHeight(false);

            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });

            // scrollTop, clientHeight, scrollHeight
          }}
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            className="w-5 h-5"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"
            ></path>
          </svg>
        </button>{" "}
      </div>
    </div>
  );
}
