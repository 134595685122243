/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { Fragment, useState, useEffect, useContext } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  XMarkIcon as XMarkIconOutline,
  ArrowUturnLeftIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowUturnRightIcon,
  CheckIcon,
  ClockIcon,
  QuestionMarkCircleIcon,
  XMarkIcon as XMarkIconMini,
} from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Context } from "../context/ContextProvider";
import instance, { useAxios, useAxiosSeconde } from "../api/api";
import Notifications from "../components/Notifications";
// import {  } from 'react-router-dom'

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Cart({ t }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const {
    categories,
    items,
    setItems,
    addOrder,
    setOrders,
    orders,
    newList,
    setNewList,
    amountOfItemsInCart,
    handleAddProduct,
    cartItmes,
    setCartItems,
    handleRemoveProduct,
    totalPrice,
    setCategories,
    company,
    user,
    setUser,
    show,
    setShow,
  } = useContext(Context);

  const handleNotification = () => {
    setShow(true);

    setTimeout(() => {
      setShow(false);
      // JSON.parse(localStorage.getItem("Order")).length === 0 &&
      //   navigate(`/${localStorage.getItem("CN")}`);
    }, 500);
  };

  // const [select, setSelect] = useState(0);

  // const handleFields = (e,i) => {
  //   console.log("e: ", e.target.name == i , e.target.value);
  //   if (e.target.name == i) {
  //     setSelect(e.target.value);
  //   } else {
  //     console.log("jj");
  //   }
  //   // setHandleError("");
  //   // setNewObject({ ...newOject, [e.target.name]: e.target.value });
  // };
  const { data, loadingSeconde, errorSeconde } = useAxiosSeconde({
    method: "GET",
    url: `/companies/get-company-name/${
      localStorage.getItem("CN") !== null && localStorage.getItem("CN")
    }`,
    headers: {
      accept: "*/*",
    },
  });

  const cartFull =
    JSON.parse(localStorage.getItem("Order")).length > 0
      ? JSON.parse(localStorage.getItem("Order"))
          .filter((p) => p.company_id === data?._id)
          .map((item) => item.qty)
          .reduce((previous, item) => previous + item, 0)
      : 0;

  const updateCart = async (_id) => {
    // console.log("here dd .....", company);
    const filter = JSON.parse(localStorage.getItem("Order")).filter(
      (order) => order._id !== _id && order.company_id === data?._id
    );
    // console.log("filter: ", filter);
    try {
      await instance.post("/orders/update", {
        _id: localStorage.getItem("OrderId"),
        orders: filter,
      });
      // console.log("data :", data);
      localStorage.setItem("Order", JSON.stringify(filter));

      // navigate("/cart");
      // console.log("jj: ", filter);
      filter.length === 0
        ? localStorage.setItem("CountCart", 0)
        : localStorage.setItem("CountCart", cartFull);
      setTimeout(() => {
        // document.location.reload();
        // window.location.href = "/cart"
        // navigate("/cart");
      }, 700);
      handleNotification();
    } catch (error) {
      console.log("error: ", error.message);
    }
  };

  const { response, loading, error } = useAxios({
    method: "POST",
    url: `/orders/update`,
    headers: {
      accept: "*/*",
    },
    data: {
      _id: localStorage.getItem("OrderId"),
      orders: JSON.parse(localStorage.getItem("Order")),
    },
  });

  // useEffect(() => {
  //   setTimeout(() => {
  //     console.log("jj: ", localStorage.getItem("OrderId"));
  //   }, 2000);
  // }, []);

  const textCutFunc = (text) =>
    `${text
      .trim()
      .split(" ")
      .splice(0, text?.length > 25 ? 6 : 5)
      .join(" ")}  ${text?.length > 25 ? "..." : ""}`;

  return (
    <div className="bg-white">
      <Notifications title={t("removeFromCart")} />
      {loadingSeconde ? (
        <div className="flex items-center justify-center w-full py-20">
          <div className="flex justify-center items-center space-x-1 rtl:space-x-reverse text-sm text-gray-700">
            <svg
              fill="none"
              className="w-20 h-20 animate-spin"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
      ) : (
        <main className="mx-auto max-w-2xl px-4 pb-24 pt-5 sm:px-6 lg:max-w-5xl lg:px-8">
          {JSON.parse(localStorage.getItem("Order")).filter(
            (p) => p.company_id === data?._id
          ).length === 0 ? (
            <div className="flex flex-row justify-between px-5 bg-gray-200 p-3 rounded">
              <h1 className="text-1xl font-bold tracking-tight text-gray-900 sm:text-xl mt-0.5">
                {t("notProductsinCart")}
              </h1>
              <a
                href={`/${localStorage.getItem("CN")}`}
                className="flex flex-row bg-gray-600 rounded px-2 text-white "
              >
                <span className="text-1xl font-bold tracking-tight  sm:text-1xl pl-3 pr-3 pb-1 sm:mt-0.5">
                  {t("back")}
                </span>
                <ArrowUturnRightIcon
                  className="h-5 w-5 mt-0.5 sm:mt-1.5"
                  aria-hidden="true"
                />
              </a>
            </div>
          ) : (
            <>
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                {t("cart")}
              </h1>
              <div className="mt-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16">
                <section
                  aria-labelledby="cart-heading"
                  className="lg:col-span-7"
                >
                  <h2 id="cart-heading" className="sr-only">
                    Items in your shopping cart
                  </h2>

                  <ul className="divide-y divide-gray-200 border-b border-t border-gray-200">
                    {JSON.parse(localStorage.getItem("Order"))
                      .filter((p) => p.company_id === data?._id)
                      .map((product, productIdx) => (
                        <li key={product._id} className="flex py-6 sm:py-10">
                          <div className="flex-shrink-0">
                            <img
                              src={product.image.split(",")[0]}
                              alt={"image0"}
                              className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                            />
                          </div>

                          <div className="ltr:ml-4 rtl:mr-4 flex flex-1 flex-col justify-between ltr:sm:ml-6 rtl:sm:mr-6">
                            <div className="relative ltr:pr-9 rtl:pl-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0 rtl:sm:pl-0">
                              <div>
                                <div className="flex justify-between">
                                  <h3 className="text-sm">
                                    <a
                                      href={product.href}
                                      className="font-medium text-gray-700 hover:text-gray-800"
                                    >
                                      {textCutFunc(product.name)}
                                    </a>
                                  </h3>
                                </div>

                                <p className="mt-1 text-sm font-medium text-gray-900">
                                  {product.unit.toFixed(2) + " " + t("sar")}
                                </p>
                              </div>

                              <div className="mt-4 sm:mt-0 ltr:sm:pr-9 rtl:sm:pl-9">
                                <label
                                  htmlFor={`quantity-${productIdx}`}
                                  className="sr-only"
                                >
                                  Quantity, {product.qty}
                                </label>
                                <p className="text-gray-500">
                                  {t("quantity")}: {product.qty}
                                </p>

                                <div className="absolute ltr:right-0 rtl:left-0 top-0">
                                  <button
                                    onClick={() => updateCart(product._id)}
                                    type="button"
                                    className="-m-2 inline-flex p-2 text-gray-400 hover:text-gray-500"
                                  >
                                    <span className="sr-only">Remove</span>
                                    <XMarkIconMini
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>

                            <p className="mt-4 flex space-x-2 rtl:space-x-reverse text-sm text-gray-700">
                              {product.stock > 0 ? (
                                <CheckIcon
                                  className="h-5 w-5 flex-shrink-0 text-green-500"
                                  aria-hidden="true"
                                />
                              ) : (
                                <ClockIcon
                                  className="h-5 w-5 flex-shrink-0 text-gray-300"
                                  aria-hidden="true"
                                />
                              )}

                              <span>
                                {product.stock > 0
                                  ? `${t("available")}`
                                  : `${t("notAvailable")}`}
                              </span>
                            </p>
                          </div>
                        </li>
                      ))}
                  </ul>
                </section>

                {/* Order summary */}
                <section
                  aria-labelledby="summary-heading"
                  className="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8"
                >
                  <h2
                    id="summary-heading"
                    className="text-lg font-medium text-gray-900"
                  >
                    {t("orderSummary")}
                  </h2>

                  <dl className="mt-6 space-y-4">
                    <div className="flex items-center justify-between">
                      <dt className="text-sm text-gray-600">{t("subTotal")}</dt>
                      <dd className="text-sm font-medium text-gray-900"></dd>
                    </div>
                    <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                      <dt className="flex items-center text-sm text-gray-600">
                        <span>{t("orderSummary")}</span>
                        <a
                          href="#"
                          className="ltr:ml-2 rtl:mr-2 flex-shrink-0 text-gray-400 hover:text-gray-500"
                        >
                          <span className="sr-only">
                            Learn more about how shipping is calculated
                          </span>
                          {/* <QuestionMarkCircleIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          /> */}
                        </a>
                      </dt>
                      <dd className="text-sm font-medium text-gray-900">
                        19.99 {t("sar")}
                      </dd>
                    </div>
                    <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                      <dt className="flex text-sm text-gray-600">
                        <span>{t("vat")}</span>
                        <a
                          href="#"
                          className="ltr:ml-2 rtl:mr-2 flex-shrink-0 text-gray-400 hover:text-gray-500"
                        >
                          <span className="sr-only">
                            Learn more about how tax is calculated
                          </span>
                          {/* <QuestionMarkCircleIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          /> */}
                        </a>
                      </dt>
                      <dd className="text-sm font-medium text-gray-900">
                        00.00 {t("sar")}
                      </dd>
                    </div>
                    <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                      <dt className="text-base font-medium text-gray-900">
                        {t("orderTotal")}
                      </dt>
                      <dd className="text-base font-medium text-gray-900">
                        {(totalPrice + 19.99).toFixed(2) + " " + t("sar")}
                      </dd>
                    </div>
                  </dl>

                  <div className="mt-6">
                    <a href="/checkout">
                      <button
                        type="button"
                        className="w-full rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-center font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                        // onClick={() => {
                        //   console.log("jlfd: ",localStorage.getItem("Order") != null &&
                        //   JSON.parse(localStorage.getItem("Order")).length === 0);
                        //   navigate("/checkout");
                        // }}
                        // href={"/checkout"}
                        // onClick={() =>
                        //   navigate("/checkout", { state: null })
                        // }
                      >
                        {t("continuation")}
                      </button>
                    </a>
                  </div>
                </section>
              </div>
            </>
          )}
        </main>
      )}
    </div>
  );
}
