/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useEffect, useState, useContext } from "react";
import { useAxios } from "../api/api";
import instance from "../api/api";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Register({t}) {
  const { response } = useAxios({
    method: "GET",
    url: `/companies/get-company-name/${localStorage.getItem("CN")}`,
    headers: {
      accept: "*/*",
    },
  });

  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [statusError, setStatusError] = useState("");
  const [handleError, setHandleError] = useState("");
  const [newOject, setNewObject] = useState({
    displayName: "",
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleFields = (e) => {
    console.log("error :", e.target.name);
    if (e.target.name === "password") {
      setIsError(
        newOject.password.length >= 0 && newOject.password.length <= 5
      );
    }
    setHandleError("");
    setNewObject({ ...newOject, [e.target.name]: e.target.value });
  };

  const formLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const displayName = newOject.displayName;
    const email = newOject.email;
    const password = newOject.password;

    try {
      const res = await instance.post(`/users/register-web`, {
        displayName,
        email,
        password,
        companies: localStorage.getItem("CN") != null ? response._id : null,
      });
      if (res) {
        // localStorage.setItem("Token", request.data.accessToken);
        // console.log("jj :", request.data);
        setTimeout(() => {
          setNewObject({ displayName: "", email: "", password: "" });
          setIsLoading(false);
          navigate("/login");
        }, 2000);
      }
    } catch (error) {
      console.log("error", typeof error.response.status);
      setStatusError(error.response.status);
      setHandleError(error.response.data.error);
      setIsLoading(false);
      setNewObject({ displayName: "", email: "", password: "" });
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center my-20 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto rounded"
            src={require("../images/logo512.png")}
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            متجر عطر
          </h2>
          {/* <p className="mt-2 text-center text-sm text-gray-600">
              Or{' '}
              <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
                start your 14-day free trial
              </a>
            </p> */}
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10 mx-5">
            <form
              className="space-y-6"
              action="#"
              method="POST"
              onSubmit={formLogin}
            >
              <div>
                <label
                  htmlFor="displayName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("username")}
                </label>
                <div className="mt-2">
                  <input
                    id="displayName"
                    name="displayName"
                    type="displayName"
                    value={newOject.displayName}
                    onChange={(e) => handleFields(e)}
                    autoComplete="displayName"
                    required
                    className="block w-full px-2 rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("email")}
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={newOject.email}
                    onChange={(e) => handleFields(e)}
                    autoComplete="email"
                    required
                    className="block w-full px-2 rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {statusError === 402 && handleError?.length > 0 && (
                  <span className="flex items-center font-medium  tracking-wide text-red-500 text-xs mt-1 ltr:ml-1 rtl:mr-1 ">
                    {t("emailFound")}
                  </span>
                )}
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("password")}
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={newOject.password}
                    onChange={(e) => handleFields(e)}
                    autoComplete="current-password"
                    required
                    className="block w-full px-2 rounded-md border py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {isError && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs ltr:ml-1 rtl:mr-1 ">
                    {t("lengthPassword")}
                  </span>
                )}
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label
                    htmlFor="remember-me"
                    className="rtl:mr-2 ltr:ml-2 block text-sm text-gray-900"
                  >
                    {t("remember")}
                  </label>
                </div>

                <div className="text-sm">
                  <a
                    href="/login"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    {t("youHaveAcount")}
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={
                    newOject.password.length <= 5 ||
                    newOject.email.length === 0 ||
                    newOject.displayName.length === 0
                  }
                  className={classNames(
                    newOject.password.length <= 5 ||
                      newOject.email.length === 0 ||
                      newOject.displayName.length === 0
                      ? "bg-gray-400 hover:bg-gray-400"
                      : "focus:ring-indigo-600 bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600",
                    "flex w-full border my-3  justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                  )}
                >
                  {isLoading ? (
                    <>
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-4 h-4 text-white animate-spin rtl:ml-3 ltr:mr-3"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    </>
                  ) : (
                    `${t("signup")}`
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
