/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { Fragment, useState, useEffect, useContext, useRef } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  XMarkIcon as XMarkIconOutline,
  ArrowUturnLeftIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowUturnRightIcon,
  CheckIcon,
  ClockIcon,
  QuestionMarkCircleIcon,
  XMarkIcon as XMarkIconMini,
} from "@heroicons/react/20/solid";
import { useNavigate, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Context, ContextProvider } from "../context/ContextProvider";
import instance, { useAxios, useAxiosSeconde } from "../api/api";
import Notifications from "../components/Notifications";
// import {  } from 'react-router-dom'
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import ReactStoreBadges from "react-store-badges";
import axios from "axios";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const API_KEY = "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";
// "Explain things like you would to a 10 year old learning how to code."
const systemMessage = {
  //  Explain things like you're talking to a software professional with 5 years of experience.
  role: "system",
  content:
    "Explain things like you're talking to a software professional with 2 years of experience.",
};

export default function AskaiOne({ t }) {
  const { itemNo } = useParams();

  const { isAskAI, setIsAskAI } = useContext(Context);

  useEffect(() => {
    console.log("itemNo: ", itemNo);
    setIsAskAI(true);
  }, []);
  const [loadingSeconde, setLoadingSeconde] = useState(true);

  //   useEffect(() => {
  //     setTimeout(() => {
  //       setLoadingSeconde(false);
  //     }, 1000);
  //   }, []);

  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [isFinish, setIsFinish] = useState(false);

  useEffect(() => {
    const fetchDataComments = async () => {
      setMessages([]);

      axios
        .get(`https://shawer-app.herokuapp.com/api/v1/ask/show/${itemNo}`)
        .then((res) => {
          const allMessage = res.data.chat.map((message) => {
            const dataOneMessage = {
              message: message.content,
              direction:
                message.user_id === "645c0d94de94ab8e403c959c"
                  ? "outgoing"
                  : "",
              sender:
                message.user_id !== "645c0d94de94ab8e403c959c" ? "AI" : "User",
            };

            return dataOneMessage;
          });
          if (allMessage) {
            setMessages(allMessage);
            setTimeout(() => {
              setLoadingSeconde(false);
            }, 500);
          }
          return allMessage;
        })
        .catch((e) => {
          console.log("err fetch all comment about question:", e.message);
          setLoadingSeconde(false);
        });
    };
    fetchDataComments();
  }, [itemNo]);

  return (
    <div className="bg-white">
      {loadingSeconde ? (
        <div className="flex items-center justify-center w-full py-20">
          <div className="flex justify-center items-center space-x-1 rtl:space-x-reverse text-sm text-gray-700">
            <svg
              fill="none"
              className="w-20 h-20 animate-spin"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
      ) : (
        <main className="mx-auto max-w-2xl px-4 pb-24 pt-5 sm:px-6 lg:max-w-5xl lg:px-8">
          <p className="text-xl text-Cyan-950">اسأل AI</p>

          <div className="w-full h-full px-10 p-5 ">
            {messages.length > 0 ? (
              <>
                <MainContainer>
                  <ChatContainer>
                    <MessageList scrollBehavior="smooth">
                      {messages.map((message, i) => {
                        return <Message key={i} model={message} />;
                      })}
                    </MessageList>

                    {/* <MessageInput
                  placeholder="اكتب سؤالك هنا"
                  onSend={handleSend}
                  attachButton={true}
                  attachDisabled={true}
                /> */}
                  </ChatContainer>
                </MainContainer>
                <p className="text-sm sm:text-xl bg-green-100 rounded p-2  text-green-400 flex items-center justify-center mt-5">
                  احصل على تجربة مجانية ثلاث أيام وأكثر
                </p>
                <p className="text-sm sm:text-xl  bg-blue-100 rounded p-2  text-blue-400 flex items-center justify-center mt-2">
                  حمل التطبيق الآن واكتب أسئلتك بكل سهولة
                </p>

                <div className="flex items-center justify-center gap-3 my-2">
                  <ReactStoreBadges
                    platform={"ios"}
                    url={"https://apps.apple.com/us/app/id6449437649?l=ar"}
                    locale={"en-us"}
                  />

                  <ReactStoreBadges
                    platform={"android"}
                    url={
                      "https://play.google.com/store/apps/details?id=com.adeb1991.ask"
                    }
                    locale={"en-us"}
                  />
                </div>
              </>
            ) : (
              <div>
                <p className="text-sm sm:text-xl  bg-blue-100 rounded p-2  text-blue-400 flex items-center justify-center mt-2">
                  هذه المحادثة لم يتم كتابة فيها أي سؤال
                </p>
              </div>
            )}
          </div>
        </main>
      )}
    </div>
  );
}
