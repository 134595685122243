/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { Fragment, useState, useEffect, useContext, useRef } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  XMarkIcon as XMarkIconOutline,
  ArrowUturnLeftIcon,
  ShareIcon,
} from "@heroicons/react/24/outline";
import {
  ArrowUturnRightIcon,
  CheckIcon,
  ClockIcon,
  QuestionMarkCircleIcon,
  XMarkIcon as XMarkIconMini,
} from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Context, ContextProvider } from "../context/ContextProvider";
import instance, { useAxios, useAxiosSeconde } from "../api/api";
import Notifications from "../components/Notifications";
// import {  } from 'react-router-dom'
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import ReactStoreBadges from "react-store-badges";
import axios from "axios";
import AlertShare from "../components/AlertShare";
import i18n from "../i18n/i18n";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const API_KEY = "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";
// "Explain things like you would to a 10 year old learning how to code."
const systemMessage = {
  //  Explain things like you're talking to a software professional with 5 years of experience.
  role: "system",
  content:
    "Explain things like you're talking to a software professional with 2 years of experience.",
};

export default function Askai({ t }) {
  const { isAskAI, setIsAskAI } = useContext(Context);

  useEffect(() => {
    // console.log("dd");
    setIsAskAI(true);
  }, []);
  const [loadingSeconde, setLoadingSeconde] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoadingSeconde(false);
    }, 1000);
  }, []);

  const navigate = useNavigate();
  const [messages, setMessages] = useState([
    {
      // message: "Hello, I'm ChatGPT! Ask me anything!",
      message: "مرحبا، أنا الذكاء الإصطناعي! تفضل وش تحب تعرف",
      sentTime: "just now",
      sender: "AI",
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);
  const [isFinish, setIsFinish] = useState(false);

  const handleSend = async (message) => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    localStorage.getItem("isAllow") == null &&
      localStorage.setItem("isAllow", JSON.stringify(messages.length));
    if (
      localStorage.getItem("isAllow") != null &&
      JSON.parse(localStorage.getItem("isAllow")) < 2
    ) {
      const newMessages = [...messages, newMessage];
      localStorage.setItem("isAllow", JSON.stringify(messages.length));
      setMessages(newMessages);
      setIsTyping(true);
      // await processMessageToChatGPT(newMessages);
      await responseChatGPT(newMessages);
    } else {
      // alert("please ");
      setIsFinish(true);
    }
  };

  async function processMessageToChatGPT(chatMessages) {
    // messages is an array of messages
    // Format messages for chatGPT API
    // API is expecting objects in format of { role: "user" or "assistant", "content": "message here"}
    // So we need to reformat

    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "AI") {
        role = "assistant";
      } else {
        role = "user";
      }
      return { role: role, content: messageObject.message };
    });

    // Get the request body set up with the model we plan to use
    // and the messages which we formatted above. We add a system message in the front to'
    // determine how we want chatGPT to act.
    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: [
        systemMessage, // The system message DEFINES the logic of our chatGPT
        ...apiMessages, // The messages from our chat with ChatGPT
      ],
    };

    await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        console.log(data);
        setMessages([
          ...chatMessages,
          {
            message: data.choices[0].message.content,
            sender: "AI",
          },
        ]);
        setIsTyping(false);
      });
  }

  // const intervalRef = useRef(null);
  // const [count, setCount] = useState("");

  // const jj = (t) => {
  //   let index = 0;
  //   // const text = "hello can you here me?".trim();
  //   intervalRef.current = setInterval(() => {
  //     // messages.slice(-1)[0].text.length
  //     if (index < t.length) {
  //       // element += text.charAt(index);
  //       setCount((e) => [...e, t.charAt(index)]);
  //       // setCount((e) => [...e, messages.slice(-1)[0].text.charAt(index)]);
  //       index++;
  //     }
  //   }, 20);

  //   return () => {
  //     clearInterval(intervalRef.current);

  //     // setLast(false);
  //     // setChatLast(false);
  //   };
  // };

  const responseChatGPT = async (messages) => {
    // console.log(
    //   "messages: ",
    //   messages
    //     .filter((m) => m.sender === "user")
    //     .map((c) => {
    //       return { role: "user", content: c.message };
    //     })
    // );
    try {
      setIsTyping(true);

      const response = await axios
        .request({
          method: "POST",
          url: "https://chatgpt-api6.p.rapidapi.com/standard-gpt",
          headers: {
            "content-type": "application/json",
            "X-RapidAPI-Key":
              "86e79abccfmsh8b955a0554166b6p1648f8jsn97e79a086e09",
            "X-RapidAPI-Host": "chatgpt-api6.p.rapidapi.com",
          },
          data: {
            conversation: messages
              .filter((m) => m.sender === "user")
              .map((c) => {
                return { role: "user", content: c.message };
              }),
          },
        })
        .catch((error) => {
          // alert("")
          console.log("error", error.message);
        });
      console.log("data message sdff", response.data);

      if (response.data.success === true) {
        const newMessage = {
          message: response.data.answer.content,
          // direction: "outgoing",
          sender: "AI",
        };

        setMessages([...messages, newMessage]);

        setIsTyping(false);
      } else {
        // setIsTyping(false);
      }
    } catch (error) {
      // setIsTyping(false);
      console.error("error message: ", error.message);
      // alert("حاول مرة أخرى");
    }
  };
  const [alertShare, setAlertShare] = useState(false);

  return (
    <div className="bg-white">
      <AlertShare
        alertShare={alertShare}
        setAlertShare={setAlertShare}
        i18n={i18n}
        shareLink={`رابط اسأل الذكاء الإصطناعي - قم بالتجربة الآن مجاناً https://naem.me/askai/`}
      />
      {loadingSeconde ? (
        <div className="flex items-center justify-center w-full py-20">
          <div className="flex justify-center items-center space-x-1 rtl:space-x-reverse text-sm text-gray-700">
            <svg
              fill="none"
              className="w-20 h-20 animate-spin"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
      ) : (
        <main className="mx-auto max-w-2xl px-4 pb-24 pt-5 sm:px-6 lg:max-w-5xl lg:px-8">
          <div className="flex flex-row justify-between mx-5">
            <p className="text-xl text-Cyan-950">اسأل الذكاء الإصطناعي</p>
            <ShareIcon
              onClick={() => setAlertShare(true)}
              className=" h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>

          {isFinish ? (
            <>
              <p className="text-sm sm:text-xl bg-red-100 rounded p-2  text-red-400 flex items-center justify-center mt-2">
                عفواً لا استطيع الرد على كل اسئتك هنا
              </p>
              <p className="text-sm sm:text-xl bg-green-100 rounded p-2  text-green-400 flex items-center justify-center mt-2">
                احصل على تجربة مجانية ثلاث أيام وأكثر
              </p>
              <p className="text-sm sm:text-xl  bg-blue-100 rounded p-2  text-blue-400 flex items-center justify-center mt-2">
                حمل التطبيق الآن واكتب أسئلتك بكل سهولة
              </p>

              <div className="flex items-center justify-center gap-3 my-5">
                <ReactStoreBadges
                  platform={"ios"}
                  url={"https://apps.apple.com/us/app/id6449437649?l=ar"}
                  locale={"en-us"}
                />

                <ReactStoreBadges
                  platform={"android"}
                  url={
                    "https://play.google.com/store/apps/details?id=com.adeb1991.ask"
                  }
                  locale={"en-us"}
                />
              </div>
            </>
          ) : null}
          <div className="w-full max-h-96 p-5">
            <MainContainer style={{ height: "500px" }}>
              <ChatContainer>
                <MessageList
                  scrollBehavior="smooth"
                  typingIndicator={
                    isTyping ? (
                      <TypingIndicator content="الذكاء الإصطناعي يرد الآن" />
                    ) : null
                  }
                >
                  {messages.map((message, i) => {
                    return <Message key={i} model={message} />;
                  })}
                </MessageList>

                <MessageInput
                  placeholder="اكتب سؤالك هنا"
                  onSend={handleSend}
                  attachButton={true}
                  attachDisabled={true}
                />
              </ChatContainer>
            </MainContainer>
          </div>
        </main>
      )}
    </div>
  );
}
