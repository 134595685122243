import { useState, useEffect } from "react";
import "../App.css";
import { useTranslation } from "react-i18next";
import i18n from "../i18n/i18n";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ProductList from "./ProductList";
import DetailProduct from "./DetailProduct";
import NavDisClosure from "../components/NavDisClosure";
import Footer from "../components/Footer";
import Cart from "./Cart";
import Login from "./Login";
import Profile from "./Profile";
import Register from "./Register";
import Checkout from "./Checkout";
import ResetPassword from "./ResetPassword";
import Order from "./Order";
import Orders from "./Orders";
import CheckPayment from "./CheckPayment";
import CheckPaymentClickPay from "./CheckPaymentClickPay";
import Askai from "./Askai";
import AskaiOne from "./AskaiOne";

function RouterBase() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    i18n.changeLanguage("ar");
    document.body.dir = i18n.dir();
  }, []);

  const lang = i18n.dir();
  const changeLanguage = (lng) => {
    if (lng === "rtl") {
      i18n.changeLanguage("er");
      document.body.dir = i18n.dir();
    } else {
      i18n.changeLanguage("ar");
      document.body.dir = i18n.dir();
    }
  };

  return (
    <BrowserRouter>
      <NavDisClosure lang={lang} changeLanguage={changeLanguage} t={t} />
      <div className="flex flex-col min-h-screen">
        <Routes>
          <Route
            exact
            path={"/:companyName"}
            element={
              <ProductList
                lang={lang}
                open={open}
                setOpen={setOpen}
                changeLanguage={changeLanguage}
                t={t}
              />
            }
          />
          <Route exact path="/detail/:_id" element={<DetailProduct t={t} />} />
          <Route
            exact
            path="/cart"
            element={
              localStorage.getItem("Token") == null ? (
                <Navigate to="/login" />
              ) : (
                // ) : localStorage.getItem("Order") == null ? (
                //   <Navigate to={`/${localStorage.getItem("CN")}`} />
                <Cart t={t} i18n={i18n} />
              )
            }
          />
          <Route
            exact
            path="/profile"
            element={
              localStorage.getItem("Token") == null ? (
                <Navigate to="/login" />
              ) : (
                <Profile t={t} />
              )
            }
          />
          <Route path={`/login`} element={<Login t={t} />} />
          <Route path={`/register`} element={<Register t={t} />} />
          <Route
            exact
            path={`/check-payment-card/:respStatus/:tranRef`}
            element={<CheckPaymentClickPay t={t} />}
          />
          <Route
            path={`/checkout`}
            element={
              localStorage.getItem("Order") === null ||
              (localStorage.getItem("Order") !== null &&
                JSON.parse(localStorage.getItem("Order")).length === 0) ? (
                <Navigate to={"/" + localStorage.getItem("CN")} />
              ) : (
                <Checkout t={t} />
              )
            }
          />
          <Route path={`/order/:tranRef`} element={<Order t={t} />} />
          <Route path={`/check-payment`} element={<CheckPayment t={t} />} />
          {/* <Route exact path="/orderat/order-by-check-card"  element={<CheckPaymentClickPay t={t} />} /> */}
          {/* <Route path={`/orders`} element={<Orders />} /> */}
          <Route
            path={`/orders`}
            element={
              localStorage.getItem("Token") == null ? (
                <Navigate to="/login" />
              ) : (
                <Orders t={t} />
              )
            }
          />
          {/* <Route path={`/profile`} element={<Profile />} /> */}
          <Route
            path={`/reset-password/:token`}
            element={<ResetPassword t={t} />}
          />
          <Route path={`/askai`} element={<Askai t={t} />} />
          <Route path={`/askaichat/:itemNo`} element={<AskaiOne t={t} />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default RouterBase;
