/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { useState, useEffect, useContext, useRef } from "react";
import { Disclosure, RadioGroup, Tab } from "@headlessui/react";
import { StarIcon } from "@heroicons/react/20/solid";
import {
  HeartIcon,
  MinusIcon,
  PlusIcon,
  CurrencyDollarIcon,
  GlobeAmericasIcon,
} from "@heroicons/react/24/outline";
import { useAxios, useAxiosSeconde } from "../api/api";
import { useParams, useNavigate } from "react-router-dom";
import { Context } from "../context/ContextProvider";
import Notifications from "../components/Notifications";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DetailProduct({ t }) {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const {
    categories,
    items,
    setItems,
    addOrder,
    setOrders,
    orders,
    newList,
    setNewList,
    amountOfItemsInCart,
    handleAddProduct,
    cartItmes,
    setCartItems,
    handleRemoveProduct,
    totalPrice,
    setCategories,
    setCompany,
    setShow,
    show,
    selectedColor,
    setSelectedColor,
    selectedSize,
    setSelectedSize,
    arrColor,
    setArrColor,
    arrSize,
    setArrSize,
  } = useContext(Context);

  const [select, setSelect] = useState(false);

  const [sizeArray, setSizeArray] = useState([
    { name: "XXS", inStock: true },
    { name: "XS", inStock: true },
    { name: "S", inStock: true },
    { name: "M", inStock: true },
    { name: "L", inStock: true },
    { name: "XL", inStock: false },
  ]);
  const [colorArray, setColorArray] = useState(["#1da1f2", "#f47373"]);
  // const [colorArray, setColorArray] = useState([
  //   { bgColor: "bg-[#1da1f2]", selectedColor: "ring-gray-900" },
  //   {

  //     bgColor: "bg-gray-400",
  //     selectedColor: "ring-gray-400",
  //   },
  // ]);

  const { response, loading, error } = useAxios({
    method: "GET",
    url: `/products/detail/${_id}`,
    headers: {
      // no need to stringify
      accept: "*/*",
    },
    // data: {  // no need to stringify
    //     userId: 1,
    //     id: 19392,
    //     title: 'title',
    //     body: 'Sample text',
    // },
  });

  const handleNotification = (id) => {
    id === _id && setSelect(true);
    setShow(true);
    setTimeout(() => {
      setShow(false);
      setSelect(false);
    }, 1000);
  };

  const textCutFunc = (text) =>
    `${text
      .trim()
      .split(" ")
      .splice(0, text?.length > 25 ? 3 : 4)
      .join(" ")}  ${text?.length > 25 ? "..." : ""}`;

  const cartFull =
    localStorage.getItem("Order") != null &&
    JSON.parse(localStorage.getItem("Order")).length > 0
      ? JSON.parse(localStorage.getItem("Order"))
          .filter((p) => p.company_id === response && response[0].company_id)
          .map((item) => item.qty)
          .reduce((previous, item) => previous + item, 0)
      : 0;

  const { data, loadingSeconde, errorSeconde } = useAxiosSeconde({
    method: "GET",
    url: `/companies/company-limits?companyName=${localStorage.getItem(
      "CN"
    )}&limit=20`,
    headers: {
      accept: "*/*",
    },
  });

  const oneFetch = useRef(true);
  useEffect(() => {
    if (oneFetch.current || loading === false) {
      oneFetch.current = false;
      localStorage.getItem("Order") != null &&
        setCartItems(JSON.parse(localStorage.getItem("Order")));

      // setCategories([
      //   "all",
      //   ...new Set(
      //     response
      //       ?.map((item) => item.category)
      //       .filter((category) => category.trim() !== "")
      //   ),
      // ]);

      // localStorage.setItem("CN", companyName);
      // filterItems("all");
      // // console.log("c: ",  localStorage.getItem("Order") == null);
      // // console.log("company: ",  jj);

      setTimeout(() => {
        console.log("here ...");
        // console.log("data", `${response[0]?.colors[0].substring(0,response[0]?.colors[0].length - 1)}`);
        // setSelectedColor(response[0]?.colors[0])

        // setCompany(data);
        setIsLoading(false);
      }, 100);
    }
  }, [loading]);

  return (
    <>
      <Notifications title={t("addedToCart")} />
      {loading ? (
        <div className="flex items-center justify-center w-full py-20">
          <div className="flex justify-center items-center space-x-1 rtl:space-x-reverse text-sm text-gray-700">
            <svg
              fill="none"
              className="w-20 h-20 animate-spin"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
      ) : (
        <div className="sm:mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
          <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8  ">
            {/* Image gallery */}
            {/* Image selector */}
            <Tab.Group as="div" className="flex flex-col-reverse">
              <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
                <Tab.List className="grid grid-cols-4 gap-6">
                  {response &&
                    response[0].image.split(",").map((image, index) => (
                      <Tab
                        key={index}
                        className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                      >
                        {({ selected }) => (
                          <>
                            <span className="sr-only"> {image} </span>
                            <span className="absolute inset-0 overflow-hidden rounded-md">
                              <img
                                src={image}
                                alt=""
                                className="h-full w-full object-cover object-center "
                              />
                            </span>
                            <span
                              className={classNames(
                                selected
                                  ? "ring-indigo-500"
                                  : "ring-transparent",
                                "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </Tab>
                    ))}
                </Tab.List>
              </div>

              <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
                {response[0].image
                  ? response[0].image.split(",").map((image, key) => (
                      <Tab.Panel key={key}>
                        <img
                          src={image}
                          alt={key}
                          className="h-full w-full object-cover object-center sm:rounded-lg"
                        />
                      </Tab.Panel>
                    ))
                  : ""}
              </Tab.Panels>
            </Tab.Group>

            {/* Product info */}
            <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
              <h1 className="text-1xl md:text-3xl font-bold tracking-tight text-gray-900">
                {response[0].name ? response[0].name : ""}
              </h1>
              <p className="mt-1 text-xl text-gray-600">{response[0].marker}</p>

              <div className="mt-1">
                <h2 className="sr-only">Product information</h2>
                <p className="text-md md:text-2xl tracking-tight text-green-500">
                  {response[0].unit
                    ? response[0].unit.toFixed(2) + " " + t("sar") + " "
                    : ""}
                  <span className=" line-through text-red-700">
                    {response[0].basicPrice
                      ? response[0].basicPrice.toFixed(2) + " " + t("sar")
                      : ""}
                  </span>
                </p>
              </div>

              {/* Color picker */}
              {response[0]?.colors.length > 0 ? (
                <div>
                  <h2 className="text-sm font-medium text-gray-900 mt-8">
                    {t("color")}
                  </h2>

                  <RadioGroup
                    value={selectedColor}
                    onChange={(value) => {
                      setSelectedColor(value);
                      setArrColor((colors) => [...colors, value]);
                    }}
                    className="mt-2"
                  >
                    <RadioGroup.Label className="sr-only">
                      Choose a color
                    </RadioGroup.Label>
                    <div className="flex items-center space-x-3 space-x-reverse">
                      {response[0]?.colors.map((color) => (
                        <RadioGroup.Option
                          key={color}
                          value={color}
                          className={({ active, checked }) =>
                            classNames(
                              "relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none"
                            )
                          }
                          style={{
                            outline: `solid 2px ${
                              color === selectedColor ? selectedColor : "#fff"
                            }`,
                            outlineOffset: selectedColor ? "1px" : null,
                          }}
                        >
                          {/* <RadioGroup.Label as="span" className="sr-only">
                          {color.name}
                        </RadioGroup.Label> */}
                          <span
                            aria-hidden="true"
                            className={classNames(
                              // `bg-[${color.replace(/["]+/g, '')}]`,
                              "h-8 w-8 rounded-full border border-black border-opacity-10 "
                            )}
                            style={{ backgroundColor: color }}
                          />
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
              ) : null}

              {/* Size picker */}
              {response[0]?.sizes.length > 0 ? (
                <div className="mt-8">
                  <div className="flex items-center justify-between">
                    <h2 className="text-sm font-medium text-gray-900">
                      {t("size")}
                    </h2>
                  </div>

                  <RadioGroup
                    value={selectedSize}
                    onChange={(value) => {
                      setSelectedSize(value);
                      setArrSize((sizes) => [...sizes, value.name]);
                    }}
                    className="mt-2"
                  >
                    <RadioGroup.Label className="sr-only">
                      Choose a size
                    </RadioGroup.Label>
                    <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
                      {response[0]?.sizes.map((size) => (
                        <RadioGroup.Option
                          key={size.name}
                          value={size}
                          className={({ active, checked }) =>
                            classNames(
                              size.inStock
                                ? "cursor-pointer focus:outline-none"
                                : "cursor-not-allowed opacity-25",
                              active
                                ? "ring-2 ring-indigo-500 ring-offset-2"
                                : "",
                              checked
                                ? "border-transparent bg-indigo-600 text-white hover:bg-indigo-700"
                                : "border-gray-200 bg-white text-gray-900 hover:bg-gray-50",
                              "flex items-center justify-center rounded-md border py-3 px-3 text-sm font-medium uppercase sm:flex-1"
                            )
                          }
                          disabled={!size.inStock}
                        >
                          <RadioGroup.Label as="span">
                            {size.name}
                          </RadioGroup.Label>
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
              ) : null}

              <div className="mt-8">
                <h3 className="sr-only">Description</h3>

                <div
                  className="text-sm space-y-6 text-base text-gray-700"
                  dangerouslySetInnerHTML={{
                    __html: response[0].description
                      ? response[0].description
                      : "",
                  }}
                />
              </div>

              <div className="sm:flex-col1 mt-10 flex">
                <button
                  onClick={() => {
                    if (
                      response[0]?.colors.length > 0 &&
                      arrColor.length === 0
                    ) {
                      alert("لم يتم اختيار اللون");
                    } else if (
                      response[0]?.colors.length > 0 &&
                      arrSize.length === 0
                    ) {
                      alert("لم يتم اختيار المقاس");
                    } else {
                      handleAddProduct(response[0]) & (cartItmes?.length > 0) &&
                        localStorage.setItem(
                          "Order",
                          JSON.stringify(cartItmes)
                        );
                      handleNotification(response[0]._id);
                    }
                  }}
                  className="focus:ring-indigo-600   bg-indigo-600 hover:bg-indigo-500 focus-visible:outline-indigo-600 flex w-full border my-3 justify-center rounded-md  px-3 py-2 text-sm font-bold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                >
                  {show && select ? (
                    <>
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-5 h-5 text-white animate-spin rtl:ml-3 ltr:mr-3"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                    </>
                  ) : (
                    `${t("toCart")}`
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {loadingSeconde === false ? (
        <div className="sm:mx-auto  divide-gray-200 border-t mt-10 py-10 mx-5   ">
          <h2 className="text-2xl sm:text-4xl font-bold tracking-tight text-gray-900 my-10 mb-16 ">
            {t("otherProducts")}
          </h2>
          <div className="mt-6 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-3 xl:grid-cols-4 md:gap-y-6 lg:gap-x-8 ">
            {data
              ?.filter((p) => p._id !== _id)
              .map((product) => (
                <div key={product._id} className="group relative">
                  <button
                    onClick={() => {
                      localStorage.setItem("CountCart", cartFull);
                      // navigate(`/detail/${product._id}`);
                      // document.location.reload();
                      window.location.href = `/detail/${product._id}`;
                    }}
                    className="text-start"
                  >
                    <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80">
                      <img
                        src={
                          product.image.includes(",")
                            ? product.image.split(",")[0]
                            : product.image
                        }
                        alt={product.imageAlt}
                        // className="h-full w-full object-cover object-center"
                        className={classNames(
                          isLoading ? "animate-pulse w-full" : "",
                          "h-full w-full object-cover object-center"
                        )}
                        style={{
                          animationFillMode: "backwards",
                          animationDelay: "150ms",
                        }}
                      />
                    </div>

                    <h3
                      className={classNames(
                        isLoading ? "animate-pulse w-full" : "",
                        "mt-4 text-sm text-gray-700"
                      )}
                    >
                      <a href={product.href}>
                        <span className="absolute inset-0" />
                        {textCutFunc(product.name)}
                      </a>
                    </h3>
                    <p className="mt-1 text-sm text-indigo-700">
                      {product.marker}
                    </p>
                    <div className="flex flex-row">
                      <p className="mt-1 text-xs text-green-600 ltr:mr-2 rtl:ml-2">
                        {product.unit
                          ? product.unit?.toFixed(2) + " " + t("sar")
                          : ""}
                      </p>
                      <p className="mt-1 text-xs text-red-600 line-through">
                        {product.basicPrice
                          ? product.basicPrice?.toFixed(2) + " " + t("sar")
                          : ""}
                      </p>
                    </div>
                  </button>

                  <div className="mt-6">
                    <button
                      onClick={() => {
                        handleAddProduct(product) & (cartItmes?.length > 0) &&
                          localStorage.setItem(
                            "Order",
                            JSON.stringify(cartItmes)
                          );
                        handleNotification(product._id);
                      }}
                      className="w-full text-xs relative flex items-center justify-center rounded-md border border-transparent bg-gray-100 px-8 py-2 sm:text-sm font-medium text-gray-900 hover:bg-gray-200 focus:bg-indigo-600 focus:text-white"
                    >
                      {t("toCart")} <span className="sr-only"></span>
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full py-20">
          <div className="flex justify-center items-center space-x-1 rtl:space-x-reverse text-sm text-gray-700">
            <svg
              fill="none"
              className="w-20 h-20 animate-spin"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
      )}
    </>
  );
}
