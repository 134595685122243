import { Fragment, useState, useEffect, useContext, useRef } from "react";
import { Context } from "../context/ContextProvider";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import {
  useAxios,
  useAxiosDublecate,
  useAxiosInviceOrder,
  useAxiosSeconde,
} from "../api/api";
import jwt_decode from "jwt-decode";

const products = [
  {
    id: 1,
    name: "Cold Brew Bottle",
    description:
      "This glass bottle comes with a mesh insert for steeping tea or cold-brewing coffee. Pour from any angle and remove the top for easy cleaning.",
    href: "#",
    quantity: 1,
    price: "$32.00",
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/confirmation-page-05-product-01.jpg",
    imageAlt: "Glass bottle with black plastic pour top and mesh insert.",
  },
];

// const response = []

export default function Order({ t }) {
  const {
    categories,
    items,
    setItems,
    addOrder,
    setOrders,
    orders,
    newList,
    setNewList,
    amountOfItemsInCart,
    handleAddProduct,
    cartItmes,
    setCartItems,
    handleRemoveProduct,
    totalPrice,
    setCategories,
  } = useContext(Context);
  const navigate = useNavigate();
  const { tranRef } = useParams();
  const { state } = useLocation();

  const textCutFunc = (text) =>
    `${text
      .trim()
      .split(" ")
      .splice(0, text?.length > 25 ? 6 : 5)
      .join(" ")}  ${text?.length > 25 ? "..." : ""}`;

  const prographCutFunc = (text) =>
    `${text.split(" ").splice(0, 10).join(" ")} ...`;

  // const { data, loadingSeconde, errorSeconde } = useAxiosSeconde({
  //   method: "GET",
  //   url: `/invoices/${itemNo}`,
  //   headers: {
  //     accept: "*/*",
  //   },
  // });

  const { data1, data2, loading, error } = useAxiosInviceOrder(
    // jwt_decode(localStorage.getItem("Token")).user._id,
    {
      method: "GET",
      url: `/invoices/transition-ref/${tranRef}`,
      headers: {
        accept: "*/*",
      },
    },
    {
      method: "GET",
      url: `/orders/getOrder/`,
      headers: {
        accept: "*/*",
      },
    }
  );

  const oneFetch = useRef(true);
  useEffect(() => {
    if (oneFetch.current || loading === false) {
      setCartItems([]);
      localStorage.setItem("Order", JSON.stringify([]));
      localStorage.removeItem("OrderId");
      localStorage.setItem("CountCart", 0);
      oneFetch.current = false;

      // console.log("c: ",  localStorage.getItem("Order") == null);
      // console.log("company: ",  jj);
    }
  }, [loading]);

  const subTotal = (orders) =>
    orders.reduce((price, item) => price + item.qty * item.unit, 0).toFixed(2);

  // const { data, loadingSeconde } = useAxiosSeconde({
  //   method: "GET",
  //   url: `/orders/getOrder/${_id}`,
  //   headers: {
  //     accept: "*/*",
  //   },
  // });

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center w-full py-20">
          <div className="flex justify-center items-center space-x-1 rtl:space-x-reverse text-sm text-gray-700">
            <svg
              fill="none"
              className="w-20 h-20 animate-spin"
              viewBox="0 0 32 32"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
                fill="currentColor"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </div>
      ) : (
        <main className="bg-white px-4 pb-24 pt-16 sm:px-6 sm:pt-24 lg:px-8 lg:py-32">
          <div className="mx-auto max-w-3xl">
            <div className="max-w-xl">
              <h1 className="text-base font-medium text-indigo-600">
                {t("thankYou")}
              </h1>
              <p className="mt-2 text-2xl sm:text-4xl  font-bold tracking-tight">
                {t("orderInWay")}
              </p>
              <p className="mt-2 text-base text-gray-500">
                {t("yourOrder")} #{data1.itemNo} {t("noteOrder")}
              </p>

              <dl className="mt-12 text-sm font-medium">
                <dt className="text-gray-900">{t("trackingNumber")}</dt>
                {data1.isShipped ? (
                  <button
                    onClick={() =>
                      window.location.replace(
                        `https://www.smsaexpress.com/sa/ar/trackingdetails?tracknumbers%5B0%5D=${data1.trackingNumber}`
                      )
                    }
                    className="mt-2 text-indigo-600"
                  >
                    {data1.trackingNumber}
                  </button>
                ) : (
                  <dd className="mt-2 text-indigo-600">
                    {t("waitTrackingNumber")}
                  </dd>
                )}
              </dl>
            </div>

            <section
              aria-labelledby="order-heading"
              className="mt-10 border-t border-gray-200"
            >
              <h2 id="order-heading" className="sr-only">
                {t("yourOrder")}
              </h2>

              <h3 className="sr-only">Items</h3>
              {data2?.orders.map((order) => (
                <div
                  key={order?._id}
                  className="flex space-x-6 rtl:space-x-reverse border-b border-gray-200 py-10"
                >
                  <img
                    src={
                      order?.image === undefined
                        ? "https://storage.googleapis.com/proudcity/mebanenc/uploads/2021/03/placeholder-image.png"
                        : order.image.split(",")[0]
                    }
                    alt={"image0"}
                    className="h-20 w-20 flex-none rounded-lg bg-gray-100 object-cover object-center sm:h-40 sm:w-40"
                  />
                  <div className="flex flex-auto flex-col">
                    <div>
                      <h4 className="font-medium text-gray-900">
                        <a href={order?.href}>{textCutFunc(order?.name)}</a>
                      </h4>
                      <p className="mt-2 text-sm text-gray-600">
                        {order?.description === undefined
                          ? ""
                          : prographCutFunc(order?.description)}
                      </p>
                    </div>
                    <div className="mt-1 flex flex-1 items-end ">
                      <dl className="flex space-x-4 ltr:divide-x divide-gray-200 text-sm sm:space-x-6 rtl:space-x-reverse">
                        <div className="flex">
                          <dt className="font-medium text-gray-900">
                            {t("quantity") + ":"}
                          </dt>
                          <dd className="ltr:ml-2 rtl:mr-2 text-gray-700">
                            {order?.qty}
                          </dd>
                        </div>
                        <div className="flex ltr:pl-4 rtl:border-r rtl:pr-4   ltr:sm:pl-6 rtl:sm:pr-6 ">
                          <dt className="font-medium text-gray-900">
                            {t("price") + ":"}
                          </dt>
                          <dd className="ltr:ml-2 rtl:mr-2 text-gray-700">
                            {order.unit.toFixed(2) + " " + t("sar")}
                          </dd>
                        </div>
                      </dl>
                    </div>
                    <div className="mt-3 flex flex-1 items-end ">
                      <dl className="flex space-x-4 ltr:divide-x divide-gray-200 text-sm sm:space-x-6 rtl:space-x-reverse">
                        {order.color?.length > 0 ? (
                          <div className="flex">
                            <dt className="font-medium text-gray-900 ">
                              {t("color") + ":"}
                            </dt>
                            {/* <dd
                            className="ltr:ml-2 rtl:mr-2 text-gray-700 h-5 w-5 rounded-xl ring-2"
                            style={{ backgroundColor: order.color }}
                          ></dd> */}
                            {order.color?.map((color) => (
                              <dd
                                className="ltr:ml-2 rtl:mr-2 text-gray-700 h-5 w-5 rounded-xl ring-2"
                                style={{ backgroundColor: color }}
                              ></dd>
                            ))}
                          </div>
                        ) : null}

                        {order.size?.length > 0 ? (
                          <div className="flex flex-row gap-1 ltr:pl-4 rtl:border-r rtl:pr-4   ltr:sm:pl-6 rtl:sm:pr-6 ">
                            <p className="text-gray-500 ">{t("size")}:</p>
                            {order.size.map((size, k) => (
                              <p className="text-gray-500 ">
                                {size}{" "}
                                {k !== order.size?.length - 1 ? (
                                  <span>-</span>
                                ) : (
                                  " "
                                )}
                              </p>
                            ))}
                          </div>
                        ) : null}
                      </dl>
                    </div>
                  </div>
                </div>
              ))}

              <div className="ltr:sm:ml-40 rtl:sm:mr-40 ltr:sm:pl-6 rtl:sm:pr-6 ">
                <h3 className="sr-only">معلوماتك</h3>

                <h4 className="sr-only">العنوان</h4>
                <dl className="grid grid-cols-2 gap-x-6 py-10 text-sm">
                  <div>
                    <dt className="font-medium text-gray-900">
                      {t("shippingAddress")}
                    </dt>
                    <dd className="mt-2 text-gray-700">
                      <address className="not-italic">
                        <span className="block">
                          {jwt_decode(localStorage.getItem("Token")).user.city}
                        </span>
                        <span className="block">
                          {
                            jwt_decode(localStorage.getItem("Token")).user
                              .neighborhood
                          }
                        </span>
                        <span className="block">
                          {
                            jwt_decode(localStorage.getItem("Token")).user
                              .address
                          }
                        </span>
                      </address>
                    </dd>
                  </div>
                  <div>
                    <dt className="font-medium text-gray-900">
                      {t("billingAddress")}
                    </dt>
                    <dd className="mt-2 text-gray-700">
                      <address className="not-italic">
                        <span className="block">
                          {jwt_decode(localStorage.getItem("Token")).user.city}
                        </span>
                        <span className="block">
                          {
                            jwt_decode(localStorage.getItem("Token")).user
                              .neighborhood
                          }
                        </span>
                        <span className="block">
                          {
                            jwt_decode(localStorage.getItem("Token")).user
                              .address
                          }
                        </span>
                      </address>
                    </dd>
                  </div>
                </dl>

                <h4 className="sr-only">الدفع</h4>
                <dl className="grid grid-cols-2 gap-x-6 border-t border-gray-200 py-10 text-sm">
                  <div>
                    <dt className="font-medium text-gray-900">
                      {t("PaymentMethod")}
                    </dt>
                    <dd className="mt-2 text-gray-700">
                      <p>
                        {data1.paymentType
                          ? data1.paymentType
                          : `${t("onlinePayment")}`}
                      </p>
                      {/* <p>Mastercard</p>
                    <p>
                      <span aria-hidden="true">••••</span>
                      <span className="sr-only">Ending in </span>1545
                    </p> */}
                    </dd>
                  </div>
                  <div>
                    <dt className="font-medium text-gray-900">
                      {t("shippingMethod")}
                    </dt>
                    <dd className="mt-2 text-gray-700">
                      <p>
                        {data1.shippingCompany
                          ? data1.shippingCompany
                          : `${t("waitTrackingNumber")}`}
                      </p>
                      <p>{t("limitShipping")}</p>
                    </dd>
                  </div>
                </dl>

                <h3 className="sr-only">Summary</h3>

                <dl className="space-y-6 border-t border-gray-200 pt-10 text-sm">
                  <div className="flex justify-between">
                    <dt className="font-medium text-gray-900">
                      {t("subTotal")}
                    </dt>
                    <dd className="text-gray-700">
                      {/* {loading && subTotal(data2?.orders) + " " + t("sar")} */}
                      {data1.price + " " + t("sar")}
                    </dd>
                  </div>
                  {/* <div className="flex justify-between">
                <dt className="flex font-medium text-gray-900">
                  Discount
                  <span className="ltr:ml-2 rtl:mr-2 rounded-full bg-gray-200 px-2 py-0.5 text-xs text-gray-600">
                    STUDENT50
                  </span>
                </dt>
                <dd className="text-gray-700">-$18.00 (50%)</dd>
              </div> */}
                  <div className="flex justify-between">
                    <dt className="font-medium text-gray-900">
                      {t("shippingCost")}
                    </dt>
                    <dd className="text-gray-700">
                      {(19.99).toFixed(2) + " " + t("sar")}
                    </dd>
                  </div>
                  <div className="flex justify-between">
                    <dt className="font-medium text-gray-900">
                      {t("orderTotal")}
                    </dt>
                    <dd className="text-gray-900">
                      {(data1.price + 19.99).toFixed(2) + " " + t("sar")}
                    </dd>
                  </div>
                </dl>
              </div>
            </section>
          </div>
        </main>
      )}
    </>
  );
}
