import { Fragment, useContext, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  ShoppingBagIcon,
  UserCircleIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { Context } from "../context/ContextProvider";
import { useParams, useNavigate, useHref } from "react-router-dom";
import instance, { useAxios } from "../api/api";
import jwt_decode from "jwt-decode";
import Banner from "./Banner";
import Search from "./Search";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavDisClosure({ changeLanguage, lang, t }) {
  const {
    amountOfItemsInCart,
    newList,
    cartItmes,
    numberInCart,
    company,
    setCompany,
    setOrders,
    isSearch,
    setIsSearch,
    isAskAI,
    setIsAskAI,
  } = useContext(Context);

  const navigation = [
    {
      name: `${t("home")}`,
      href: `/${localStorage.getItem("CN")}`,
      current: true,
    },
    // { name: "حسابي", href: `/profile`, current: false },
    { name: `${t("orders")}`, href: `/orders`, current: false },
    // { name: "Projects", href: `/${localStorage.getItem("CN")}`, current: false },
    //   { name: "Calendar", href: "#", current: false },
  ];
  const navigate = useNavigate();
  const path = useHref();

  const { response, loading, error } = useAxios({
    method: "GET",
    url: `/companies/get-company-name/${
      localStorage.getItem("CN") !== null && localStorage.getItem("CN")
    }`,
    headers: {
      accept: "*/*",
    },
  });

  // const updateCart = async () => {
  //   console.log("here dd .....", localStorage.getItem("OrderId"), cartItmes);
  //   try {
  //     const data = await instance.post("/orders/update", {
  //       _id: localStorage.getItem("OrderId"),
  //       orders: cartItmes,
  //     });
  //     console.log("data :", data);
  //   } catch (error) {
  //     console.log("error: ", error.message);
  //   }
  // };
  useEffect(() => {
    setCompany(response);
  }, []);

  // localStorage.getItem("OrderId") max-w-7xl

  const cartFull =
    localStorage.getItem("Order") != null &&
    JSON.parse(localStorage.getItem("Order")).length > 0
      ? JSON.parse(localStorage.getItem("Order"))
          .filter((p) => p.company_id === response?._id)
          .map((item) => item.qty)
          .reduce((previous, item) => previous + item, 0)
      : 0;

  if (isAskAI) {
    return (
      <Disclosure as="nav" style={{ backgroundColor: "#082032" }}>
        {({ open }) => (
          <>
            <div className="mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="flex flex-1 ">
                  <div className="w-auto  h-8 sm:hidden">
                    <Disclosure.Button className=" rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <img
                    className="h-10 w-auto pt-0  ml-0 rtl:mr-0 "
                    src={require("../images/askai.png")}
                    alt="Your Company"
                  />
                </div>

                <div className="flex flex-1 items-center justify-end">
                  <div className="hidden sm:flex ltr:sm:pr-8 rtl:sm:pl-8">
                    <button
                      onClick={() => changeLanguage(lang)}
                      className="flex items-center text-white"
                    >
                      <img
                        src={
                          lang === "rtl"
                            ? "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                            : "https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg"
                        }
                        alt=""
                        className="block h-auto w-5 flex-shrink-0"
                      />
                      <span className="ltr:ml-3 rtl:mr-3 block text-sm font-medium">
                        {t("lang")}
                      </span>
                      <span className="sr-only">, change currency</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                <div
                  onClick={() => changeLanguage(lang)}
                  className=" text-white block rounded-md px-3 py-2 text-base font-medium "
                >
                  <button
                    // onClick={}
                    className="-m-2 flex items-center p-2 "
                  >
                    <img
                      src={
                        lang === "rtl"
                          ? "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                          : "https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg"
                      }
                      alt=""
                      className="block h-auto w-5 flex-shrink-0"
                    />
                    <span className="ml-3 rtl:mr-3 block text-base font-medium text-white">
                      {t("lang")}
                    </span>
                    <span className="sr-only">, change currency</span>
                  </button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  }

  return (
    <>
      <Banner />
      <Disclosure as="nav" className="bg-black">
        {({ open }) => (
          <>
            <div className="mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="flex flex-1 ">
                  <div className="w-auto  h-8 sm:hidden">
                    <Disclosure.Button className=" rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <img
                    className="h-10 w-auto pt-0  ml-4 rtl:mr-4 "
                    src={require("../images/logo512.png")}
                    alt="Your Company"
                  />

                  {/* </div> */}
                  <div className="hidden sm:ml-6 sm:block ">
                    <div className="flex space-x-4 space-x-reverse">
                      {navigation.map((item, key) => (
                        <button
                          key={item.name}
                          onClick={async () => {
                            navigate(`${item.href}`);
                          }}
                          className={classNames(
                            path === item.href
                              ? "bg-gray-700 text-white"
                              : "text-gray-300 hover:bg-gray-500 hover:text-white",
                            "rounded-md px-3 py-2 text-sm font-medium mt-0.5 "
                          )}
                          // aria-current={item.current}
                          // aria-current="page"
                          aria-current={path === item.href ? "page" : undefined}
                        >
                          {item.name}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="flex flex-1 items-center justify-end">
                  {path === `/${localStorage.getItem("CN")}` ? (
                    <div className=" sm:flex ltr:sm:pr-8 rtl:sm:pl-8 m-8 pt-1 sm:pt-0">
                      <button
                        // onClick={() => changeLanguage(lang)}
                        className="flex items-center text-white"
                      >
                        {/* <img
                        src={
                          lang === "rtl"
                            ? "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                            : "https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg"
                        }
                        alt=""
                        className="block h-auto w-5 flex-shrink-0"
                      /> */}
                        {/* <span className="ltr:ml-3 rtl:mr-3 block text-sm font-medium">
                        Search
                      </span> */}
                        <MagnifyingGlassIcon
                          className="h-6 w-6 flex-shrink-0  text-white hover:text-blue-400"
                          aria-hidden="true"
                          onClick={() => {
                            setCompany(response);
                            setIsSearch(true);
                          }}
                        />
                        <span className="sr-only">, change currency</span>
                      </button>
                    </div>
                  ) : null}
                  <div className="hidden sm:flex ltr:sm:pr-8 rtl:sm:pl-8">
                    <button
                      onClick={() => changeLanguage(lang)}
                      className="flex items-center text-white"
                    >
                      <img
                        src={
                          lang === "rtl"
                            ? "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                            : "https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg"
                        }
                        alt=""
                        className="block h-auto w-5 flex-shrink-0"
                      />
                      <span className="ltr:ml-3 rtl:mr-3 block text-sm font-medium">
                        {t("lang")}
                      </span>
                      <span className="sr-only">, change currency</span>
                    </button>
                  </div>

                  {/* Cart */}
                  <div className="ltr:mr-6 rtl:ml-6 flow-root ltr:lg:ml-8 rtl:lg:mr-8">
                    <button
                      onClick={() => {
                        cartFull !== 0 &&
                          localStorage.setItem("CountCart", cartFull);
                        localStorage.getItem("Order") != null &&
                          JSON.parse(localStorage.getItem("Order")).length >
                            0 &&
                          navigate("/cart");
                        // console.log("done")
                        //  &&
                        // : navigate(`/${localStorage.getItem("CN")}`);
                      }}
                      className="group -m-2 flex items-center p-2"
                    >
                      <ShoppingBagIcon
                        className="h-6 w-6 flex-shrink-0   text-white hover:text-blue-400 "
                        aria-hidden="true"
                      />
                      <span className="ltr:ml-1 rtl:mr-1 text-sm font-medium  text-white hover:text-blue-400">
                        {/* {cartFull === 0
                        ? localStorage.getItem("CountCart")
                        : cartFull} */}
                        {cartFull}
                      </span>
                      <span className="sr-only">items in cart, view bag</span>
                    </button>
                  </div>
                  <div className="ltr:mr-6 rtl:ml-6 flow-root ltr:lg:ml-8 rtl:lg:mr-8">
                    <a
                      href="/profile"
                      className="group -m-2 flex items-center p-2"
                    >
                      <UserCircleIcon
                        className="h-6 w-6 flex-shrink-0  text-white hover:text-blue-400"
                        aria-hidden="true"
                      />
                      {/* <span className="ml-2 rtl:mr-2 text-sm font-medium  text-white">
                      {cartFull === 0
                        ? localStorage.getItem("CountCart")
                        : cartFull}
                    </span> */}
                      <span className="sr-only">items in cart, view bag</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    // href={item.href}
                    onClick={async () => {
                      navigate(`${item.href}`);
                    }}
                    className={classNames(
                      path === item.href
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3  py-2 text-base font-medium "
                    )}
                    aria-current={path === item.href ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}

                <div
                  onClick={() => changeLanguage(lang)}
                  className=" text-white block rounded-md px-3 py-2 text-base font-medium "
                >
                  <button
                    // onClick={}
                    className="-m-2 flex items-center p-2 "
                  >
                    <img
                      src={
                        lang === "rtl"
                          ? "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                          : "https://upload.wikimedia.org/wikipedia/commons/0/0d/Flag_of_Saudi_Arabia.svg"
                      }
                      alt=""
                      className="block h-auto w-5 flex-shrink-0"
                    />
                    <span className="ml-3 rtl:mr-3 block text-base font-medium text-white">
                      {t("lang")}
                    </span>
                    <span className="sr-only">, change currency</span>
                  </button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>{" "}
      <Search
        isSearch={isSearch}
        setIsSearch={setIsSearch}
        t={t}
        company={company}
      />
    </>
  );
}
