import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import instance, { useAxios, useAxiosSeconde } from "../api/api";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import jwt_decode from "jwt-decode";
import { Context } from "../context/ContextProvider";

function CheckPaymentClickPay() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  // const respStatus = searchParams.get("resp_status");
  const { respStatus,tranRef } = useParams();
  const [seconds, setSeconds] = useState(5);

  const { setCartItems } = useContext(Context);

  //https://fatora-sa.herokuapp.com/api/v1/push/get-order-id?orderId=

  // const { data, loadingSeconde, errorSeconde } = useAxiosSeconde({
  //   method: "GET",
  //   url: `/companies/get-company-name/${
  //     localStorage.getItem("CN") !== null && localStorage.getItem("CN")
  //   }`,
  //   headers: {
  //     accept: "*/*",
  //   },
  // });

  // const createInvoice = async (orderId, ChoosePay, Price,Profit) => {
  //   // ChoosePay === "CARD" ? setIsLoading(true) : setIsLoadingApple(true);
  //   try {
  //     const newInvoic = await instance.post(
  //       `/invoices/create-casheir-invoice`,
  //       {
  //         itemNo: orderId, // orderId from noonpayment
  //         time: new Date()
  //           .toTimeString()
  //           .replace(/^[^:]*([0-2]\d:[0-5]\d).*$/, "$1"),
  //         date: new Date().toISOString().split("T")[0],
  //         title:
  //           localStorage.getItem("CN") !== null &&
  //           localStorage.getItem("CN").toUpperCase(),
  //         user_id: jwt_decode(localStorage.getItem("Token")).user._id,
  //         orders: localStorage.getItem("OrderId"),
  //         company_id: data._id,
  //         token_id: {
  //           _id: "638a8cc11dbde0961bb169b9",
  //           type: "ios",
  //           data: "27b6fbf996b28fde2599dcbe09a2d532da8dfaa2a14cdee96a13249bc6930325",
  //           app: "cashier",
  //           __v: 0,
  //         },
  //         paymentType: ChoosePay,
  //         price: Price,
  //         profit: Profit,
  //         isShipped: false,
  //         isDelivered: false,
  //         orderId: orderId, // orderId from noonpayment
  //       }
  //     );
  //     if (newInvoic) {
  //       // const data = await newInvoic.data;
  //       console.log("data: ");
  //       // setIsLoadingApple(false);
  //       navigate(`/order/${orderId}`);
  //       localStorage.removeItem("Profit")

  //       // setTimeout(() => {
  //       // }, 2000);
  //     } else {
  //       console.log("error");
  //       // setIsLoadingApple(false);
  //     }
  //   } catch (error) {
  //     // setIsLoadingApple(false);
  //     console.error("error: ", error.message);
  //   }
  // };

  // const { response, loading, error } = useAxios({
  //   method: "GET",
  //   url: `/push/get-order-id?orderId=${orderId}`,
  //   headers: {
  //     accept: "*/*",
  //   },
  // });

  // const { response, loading, error } = useAxios({
  //   method: "POST",
  //   url: `/push/fetchClickPayReturn`,
  //   headers: {
  //     accept: "*/*",
  //   },
  // });

  useEffect(() => {
    // console.log("res: ", response && response);
    // if (respStatus === "A" && seconds === 5) {
    //   setCartItems([]);
    //   localStorage.setItem("Order", JSON.stringify([]));
    //   localStorage.removeItem("OrderId");
    //   localStorage.setItem("CountCart", 0);
    // }
    if (respStatus) {
      var interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          clearInterval(interval);
          // response.resultCode !== 5021 && response.result.order.status !== "FAILED"
          if (respStatus === "A") {
            // navigate("/ater");
            navigate(`/order/${tranRef}`);

            // console.log("data: ", response);
            // createInvoice(orderId, "Tabby", response.result.order.amount,Number(localStorage.getItem("Profit")));
            // http://localhost:3000/check-payment?orderId=287419234078&merchantReference=ater&paymentType=Tabby
          } else {
            // console.log("error: ", response);
            navigate("/checkout", { state: { payment: false } });
          }

          // console.log("data: ", response);
        }
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  // const loading = true;
  // const response = {};

  // useEffect(() => {
  //   first;

  //   return () => {
  //     second;
  //   };
  // }, [third]);

  return (
    <div>
      {respStatus === "A" ? (
        <div className="flex items-center  w-full py-20">
          <div className="flex w-full flex-col  items-center">
            {/* <svg xmlns="http://www.w3.org/2000/svg" version="1.1">

            </svg> */}
            <CheckCircleIcon className="h-20 w-20 text-green-600" />
            <div className="w-full my-5 text-gray-500  text-center text-md sm:text-xl">
              تم الدفع بنجاح
            </div>
            <div className="mx-auto w-72 sm:w-full mt-5 text-gray-500  text-center text-xs sm:text-xl">
              سيتم تحويلك للمتجر خلال {seconds} ثانية
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center  w-full py-20">
          <div className="flex w-full flex-col  items-center">
            {/* <svg xmlns="http://www.w3.org/2000/svg" version="1.1">

          </svg> */}
            <XCircleIcon className="h-20 w-20 text-red-600" />
            <div className="w-full my-5 text-gray-500  text-center text-md sm:text-xl">
              عفواً لم يتم الدفع بنجاح
            </div>
            <div className="mx-auto w-72 sm:w-full mt-5 text-gray-500  text-center text-xs sm:text-xl">
              سيتم تحويلك للمتجر خلال {seconds} ثانية
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CheckPaymentClickPay;
